import { List, ListItem, ListItemText } from "@mui/material";
import { siteUrl } from "Src/variables";
import React from "react";
import { LINKS } from "./data";
import { NavBarLinkWrapper } from "./NavBarLinkWrapper";
const NavBar = ({ user }) => {
    const { permissions: userPermissions } = user;
    return (React.createElement(List, { sx: { display: "flex", justifyContent: "center" } },
        React.createElement(ListItem, { sx: { flexBasis: "100px", marginRight: "auto" } },
            React.createElement("a", { href: siteUrl }, "\u041D\u0430 \u0441\u0430\u0439\u0442")),
        LINKS.map((linkData) => {
            const { name, id, oldAdminUrl, Icon, permissions, url } = linkData;
            if (permissions.length && !userPermissions.some((userPermission) => permissions.includes(userPermission))) {
                return null;
            }
            return (React.createElement(ListItem, { key: id, sx: { width: "auto" } },
                React.createElement(NavBarLinkWrapper, { url: url, oldAdminUrl: oldAdminUrl },
                    Icon && (React.createElement(Icon, { sx: { position: "relative", left: "50%", transform: "translate(-50%)", fontSize: "3rem" } })),
                    React.createElement(ListItemText, { sx: { textAlign: "center", textTransform: "capitalize" } }, name))));
        }),
        React.createElement(ListItem, { sx: { flexBasis: "100px", marginLeft: "auto" } }, user.name)));
};
export { NavBar };
