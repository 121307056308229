import { CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { sendHttpReq } from "Utils/sendHttpReq";
import { MarketingLoyaltyCard } from "../../components";
import { MarketingLoyaltyList } from "./MarketingLoyaltyAchievementsList.styles";
const MarketingLoyaltyAchievementsList = ({ achievements, setAchievements, brands, onUpdateAchievement, onDeleteAchievement, }) => {
    const dispatch = useAppDispatch();
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState("");
    useEffect(() => {
        setIsLoading(true);
        sendHttpReq({ method: "get", url: "/achievements" })
            .then(({ data }) => setAchievements(data))
            .catch(() => {
            dispatch(showEventSnack({
                isShowed: true,
                type: "err",
                messageValue: "Не удалось загрузить достижения",
            }));
            setError("Не удалось загрузить достижения");
        })
            .finally(() => setIsLoading(false));
    }, []);
    if (isLoading) {
        return React.createElement(CircularProgress, { value: 10, className: 'defaultLoader' });
    }
    if (error) {
        return (React.createElement(Typography, { variant: 'h5', align: 'center' }, error));
    }
    if (!achievements.length) {
        return (React.createElement(Typography, { variant: 'h5', align: 'center' }, "\u0421\u043F\u0438\u0441\u043E\u043A \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u0439 \u043F\u0443\u0441\u0442"));
    }
    return (React.createElement(MarketingLoyaltyList, null, achievements.map((achievement) => (React.createElement(MarketingLoyaltyCard, { key: achievement.id, achievement: achievement, brands: brands, onUpdateAchievement: onUpdateAchievement, onDeleteAchievement: onDeleteAchievement })))));
};
export { MarketingLoyaltyAchievementsList };
