import { temporaryToken } from "Src/constants";
import { crmApiUrl } from "Src/variables";
import { sendHttpReq } from "Utils/sendHttpReq";
import { format } from "date-fns";
import { useEffect, useState } from "react";
const useGetTotals = () => {
    const [totals, setTotals] = useState({ noCourier: 0, total: 0 });
    const [isTotalsLoading, setIsTotalsLoading] = useState(false);
    const [refresh, setRefresh] = useState(false);
    const refetchTotals = () => setRefresh(!refresh);
    useEffect(() => {
        setIsTotalsLoading(true);
        const today = format(new Date(), "yyyy-MM-dd");
        sendHttpReq({
            method: "get",
            url: `${crmApiUrl}/courier-delivery/totals?date=${today}`,
            authHeader: temporaryToken,
        })
            .then(({ data }) => setTotals(data))
            .finally(() => setIsTotalsLoading(false));
    }, [refresh]);
    return { totals, isTotalsLoading, refetchTotals };
};
export { useGetTotals };
