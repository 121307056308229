import { sendHttpReq } from "Utils/sendHttpReq";
import { format } from "date-fns";
import { useEffect, useState } from "react";
const useGetCertificates = ({ debouncedSearchQuery, currentPage, statusIds, designIds, activationDateFrom, activationDateTo, activeUntilDateFrom, activeUntilDateTo, }) => {
    const [pagesCount, setPagesCount] = useState(1);
    const [certificates, setCertificates] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    useEffect(() => {
        setIsLoading(true);
        setCertificates([]);
        const formatDate = (date) => (date ? format(date, "yyyy-MM-dd") : "");
        const searchParams = {
            page: "" + currentPage,
            search: debouncedSearchQuery,
            statuses: statusIds.join(","),
            designs: designIds.join(","),
            activationDateFrom: formatDate(activationDateFrom),
            activationDateTo: formatDate(activationDateTo),
            activeUntilDateFrom: formatDate(activeUntilDateFrom),
            activeUntilDateTo: formatDate(activeUntilDateTo),
        };
        const searchParamsWithFullValues = Object.entries(searchParams).reduce((acc, [key, value]) => {
            if (value) {
                acc[key] = value;
            }
            return acc;
        }, {});
        sendHttpReq({
            method: "get",
            url: "/certificate/list?" + new URLSearchParams(searchParamsWithFullValues).toString(),
        })
            .then((res) => {
            setCertificates(res.data.data);
            setPagesCount(res.data.pagesCount);
        })
            .finally(() => setIsLoading(false));
    }, [
        currentPage,
        debouncedSearchQuery,
        statusIds,
        designIds,
        activationDateFrom,
        activationDateTo,
        activeUntilDateFrom,
        activeUntilDateTo,
    ]);
    return { isLoading, certificates, pagesCount };
};
export { useGetCertificates };
