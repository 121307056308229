import React from "react";
import { Paper, Table, TableContainer, Typography } from "@mui/material";
import { BasicTableBody, BasicTableHead } from "./components";
const BasicTable = ({ tableHeadCells, items, renderRow }) => {
    if (items.length === 0) {
        return (React.createElement(Typography, { sx: { mt: "2rem" }, letterSpacing: 2, align: 'center', variant: 'h5' }, "C\u043F\u0438\u0441\u043E\u043A \u043F\u0443\u0441\u0442"));
    }
    return (React.createElement(TableContainer, { component: Paper },
        React.createElement(Table, null,
            React.createElement(BasicTableHead, { tableHeadCells: tableHeadCells }),
            React.createElement(BasicTableBody, { items: items, renderRow: renderRow }))));
};
export { BasicTable };
