import React from "react";
import { render } from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ruLocale from "date-fns/locale/ru";
import { ruRU } from "@mui/material/locale";
import { ruRU as ruRU_dataGrid } from "@mui/x-data-grid";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import store from "Redux/store";
import { init as initSentry } from "@sentry/browser";
import { App } from "Components/App/App";
import { ErrorBoundary } from "Components/ErrorBoundary/ErrorBoundary";
import { apiUrl } from "./variables";
import axios from "axios";
import { getQueryVariable } from "Utils/getQueryVariable";
const tokenFromUrl = getQueryVariable('access-token');
if (tokenFromUrl) {
    localStorage.token = tokenFromUrl;
}
initSentry({
    dsn: "https://bc316588034c02bbd37b55e35a33da28@o4506013923344384.ingest.sentry.io/4506157505183744",
    environment: process.env.NODE_ENV,
    enabled: !location.host.includes("localhost"),
});
axios.defaults.baseURL = apiUrl;
const themeCommon = createTheme({}, ruRU);
const themeDataGrid = createTheme({}, ruRU_dataGrid);
render(React.createElement(React.StrictMode, null,
    React.createElement(Provider, { store: store },
        React.createElement(BrowserRouter, null,
            React.createElement(LocalizationProvider, { dateAdapter: AdapterDateFns, locale: ruLocale },
                React.createElement(ThemeProvider, { theme: themeCommon },
                    React.createElement(ThemeProvider, { theme: themeDataGrid },
                        React.createElement(ErrorBoundary, null,
                            React.createElement(App, null)))))))), document.getElementById("root"));
