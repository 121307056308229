import React, { useState } from "react";
import { Box, CircularProgress, Pagination, Typography } from "@mui/material";
import { DateRange } from "Components/DateRange/DateRange";
import { useDatePicker } from "Src/hooks";
import { OrdersCourierDeliveriesTable } from "../OrdersCourierDeliveriesTable";
import { useDelivery, useGetCompanies, useGetCouriers, useGetTotals } from "Pages/Orders/hooks";
import { useAppDispatch } from "Redux/hooks";
import { sendHttpReq } from "Utils/sendHttpReq";
import { showEventSnack } from "Redux/slices/appSlice";
import { temporaryToken } from "Src/constants";
import { LoadingButton } from "@mui/lab";
import { crmApiUrl } from "Src/variables";
import { format } from "date-fns";
const OrdersCourierDeliveries = () => {
    const dispatch = useAppDispatch();
    const { dateFrom, dateErrFrom, dateTo, dateErrTo, setDateErrFrom, setDateErrTo, setDateFrom, setDateTo } = useDatePicker();
    const { isTotalsLoading, totals, refetchTotals } = useGetTotals();
    const [couriers] = useGetCouriers();
    const [companiesData] = useGetCompanies();
    const { deliveries, getDeliveries, isLoading, pagesCount, currentPage, onCurrentPageChange, onUpdate: onUpdateDeliveries, onDelete, } = useDelivery({
        dateFrom,
        dateTo,
        dateErrFrom,
        dateErrTo,
    });
    const onUpdate = (shouldRefetch = true, delivery) => {
        onUpdateDeliveries(shouldRefetch, delivery);
        refetchTotals();
    };
    const [isExcelDownloading, setIsExcelDownloading] = useState(false);
    const onExcelDownload = () => {
        setIsExcelDownloading(true);
        const dateFromQuery = dateFrom ? format(dateFrom, "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd");
        const dateToQuery = dateTo ? format(dateTo, "yyyy-MM-dd") : format(new Date(), "yyyy-MM-dd");
        sendHttpReq({
            method: "get",
            url: `${crmApiUrl}/courier-delivery/download?dateFrom=${dateFromQuery}&dateTo=${dateToQuery}`,
            authHeader: temporaryToken,
            options: { responseType: "blob" },
        })
            .then(({ data }) => {
            const url = URL.createObjectURL(new Blob([data]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", `${new Date().getTime()}.xlsx`);
            document.body.appendChild(link);
            link.click();
            dispatch(showEventSnack({ isShowed: true, messageValue: "Данные успешно выгружены", type: "success" }));
            document.body.removeChild(link);
            URL.revokeObjectURL(url);
        })
            .finally(() => setIsExcelDownloading(false));
    };
    return (React.createElement("div", null,
        React.createElement(Box, { sx: { display: "flex", justifyContent: "space-between", alignItems: "center", pr: "2rem" } },
            React.createElement(DateRange, { dateFrom: dateFrom, dateTo: dateTo, dateErrFrom: dateErrFrom, dateErrTo: dateErrTo, setDateFrom: setDateFrom, setDateTo: setDateTo, setDateErrFrom: setDateErrFrom, setDateErrTo: setDateErrTo, dateRangeHandler: getDeliveries }),
            React.createElement(LoadingButton, { loading: isExcelDownloading, variant: 'contained', color: 'success', onClick: onExcelDownload }, "\u0412\u044B\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0432 excel"),
            isTotalsLoading ? (React.createElement(CircularProgress, null)) : (React.createElement(Box, null,
                React.createElement(Typography, { variant: 'h6' }, "\u0421\u0435\u0433\u043E\u0434\u043D\u044F"),
                React.createElement(Typography, null,
                    "\u041D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u043E \u043F\u043E\u0440\u0443\u0447\u0435\u043D\u0438\u0439: ",
                    totals.total),
                React.createElement(Typography, { sx: { color: "red" } },
                    "\u041D\u0435 \u043D\u0430\u0437\u043D\u0430\u0447\u0435\u043D\u043E \u043A\u0443\u0440\u044C\u0435\u0440\u043E\u0432: ",
                    totals.noCourier)))),
        !isLoading && pagesCount > 1 && (React.createElement(Pagination, { className: 'customPagination_teal customPagination_top', sx: { mt: "1rem" }, showFirstButton: true, showLastButton: true, page: currentPage, count: pagesCount, onChange: onCurrentPageChange })),
        React.createElement(OrdersCourierDeliveriesTable, { isLoading: isLoading, deliveries: deliveries, couriers: couriers, onDelete: onDelete, onUpdate: onUpdate, companiesData: companiesData }),
        !isLoading && pagesCount > 1 && (React.createElement(Pagination, { className: 'customPagination_teal customPagination_bottom', sx: { mt: "1rem" }, showFirstButton: true, showLastButton: true, page: currentPage, count: pagesCount, onChange: onCurrentPageChange }))));
};
export { OrdersCourierDeliveries };
