import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, MenuItem, Select, TextField, } from "@mui/material";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { sendHttpReq } from "Utils/sendHttpReq";
import { format, isDate } from "date-fns";
import React, { useState } from "react";
import { MarketingLoyaltyAdditionalDiscountsClientStatuses, allClientsValue, } from "./MarketingLoyaltyAdditionalDiscountsDialogForm.data";
const MarketingLoyaltyAdditionalDiscountsDialogForm = ({ isAddDialogOpen, selectedOrderType, onAddDialogClose, onSuccess, isEdit = false, id, item, }) => {
    var _a, _b, _c, _d;
    const dispatch = useAppDispatch();
    const [isSaving, setIsSaving] = useState(false);
    const [createAdditionalDiscountData, setCreateAdditionalDiscountData] = useState({
        date_from: (item === null || item === void 0 ? void 0 : item.date_from) || new Date(),
        date_to: (item === null || item === void 0 ? void 0 : item.date_to) || new Date(),
        increase_percent: ((_a = item === null || item === void 0 ? void 0 : item.increase_percent) === null || _a === void 0 ? void 0 : _a.toString()) || "",
        enabled: (item === null || item === void 0 ? void 0 : item.enabled) || 0,
        only_without_orders: isEdit ? (_b = item === null || item === void 0 ? void 0 : item.only_without_orders) !== null && _b !== void 0 ? _b : 1 : 1,
        users_filters: {
            ids: ((_c = item === null || item === void 0 ? void 0 : item.users_filters) === null || _c === void 0 ? void 0 : _c.ids.join(",")) || "",
            statuses: ((_d = item === null || item === void 0 ? void 0 : item.users_filters) === null || _d === void 0 ? void 0 : _d.statuses) || [],
        },
    });
    const { enabled, increase_percent: increasePercent, only_without_orders, date_from, date_to, users_filters: { statuses, ids }, } = createAdditionalDiscountData;
    const onCreateAdditionalDiscountDataChange = (e) => {
        setCreateAdditionalDiscountData(Object.assign(Object.assign({}, createAdditionalDiscountData), { [e.target.name]: e.target.value }));
    };
    const onCheckBoxChange = (e) => onCreateAdditionalDiscountDataChange({ target: { name: e.target.name, value: e.target.checked } });
    const onSelectChange = (e) => {
        const { target: { value }, } = e;
        if (value.includes(allClientsValue) && value[0] !== allClientsValue) {
            setCreateAdditionalDiscountData((prev) => {
                return Object.assign(Object.assign({}, prev), { users_filters: Object.assign(Object.assign({}, prev.users_filters), { statuses: [allClientsValue] }) });
            });
            return;
        }
        if (value[0] === allClientsValue && value.length > 1) {
            setCreateAdditionalDiscountData((prev) => {
                return Object.assign(Object.assign({}, prev), { users_filters: Object.assign(Object.assign({}, prev.users_filters), { statuses: Array.isArray(value) ? value.filter((item) => item !== allClientsValue) : value.split(",") }) });
            });
            return;
        }
        setCreateAdditionalDiscountData((prev) => {
            return Object.assign(Object.assign({}, prev), { users_filters: Object.assign(Object.assign({}, prev.users_filters), { statuses: typeof value === "string" ? value.split(",") : value }) });
        });
    };
    const onSave = () => {
        setIsSaving(true);
        const method = isEdit ? "put" : "post";
        const url = isEdit ? `/loyalty-additional-discount/${id}` : "loyalty-additional-discount/create";
        const userIds = ids.length ? ids.split(",").map((id) => id.trim()) : [];
        const statuses = createAdditionalDiscountData.users_filters.statuses.length === 1 &&
            createAdditionalDiscountData.users_filters.statuses[0] === allClientsValue
            ? []
            : createAdditionalDiscountData.users_filters.statuses;
        const data = Object.assign(Object.assign({}, createAdditionalDiscountData), { date_from: isDate(date_from) ? format(date_from, "yyyy-MM-dd HH:mm") : date_from, date_to: isDate(date_to) ? format(date_to, "yyyy-MM-dd HH:mm") : date_to, users_filters: {
                statuses,
                ids: userIds,
            } });
        if (selectedOrderType) {
            data["order_type"] = selectedOrderType;
        }
        sendHttpReq({
            method,
            url,
            data,
        })
            .then(({ data }) => {
            onSuccess(data);
            dispatch(showEventSnack({ isShowed: true, messageValue: "Доп. скидка успешно добавлена", type: "success" }));
            onAddDialogClose();
        })
            .finally(() => setIsSaving(false));
    };
    return (React.createElement(Dialog, { fullWidth: true, open: isAddDialogOpen, onClose: onAddDialogClose },
        React.createElement(DialogTitle, null,
            isEdit ? "Редактировать" : "Добавить",
            " \u0434\u043E\u043F. \u0441\u043A\u0438\u0434\u043A\u0443"),
        React.createElement(DialogContent, null,
            React.createElement(FormControl, { margin: 'normal', fullWidth: true },
                React.createElement(DateTimePicker, { inputFormat: 'yyyy-MM-dd HH:mm', mask: '____-__-__ __:__', label: '\u041E\u0442', value: date_from, onChange: (date) => date && setCreateAdditionalDiscountData((prev) => (Object.assign(Object.assign({}, prev), { date_from: date }))), renderInput: (props) => React.createElement(TextField, Object.assign({}, props)) })),
            React.createElement(FormControl, { margin: 'normal', fullWidth: true },
                React.createElement(DateTimePicker, { inputFormat: 'yyyy-MM-dd HH:mm', mask: '____-__-__ __:__', label: '\u0414\u043E', value: date_to, onChange: (date) => date && setCreateAdditionalDiscountData((prev) => (Object.assign(Object.assign({}, prev), { date_to: date }))), renderInput: (props) => React.createElement(TextField, Object.assign({}, props)) })),
            React.createElement(FormControl, { margin: 'normal', fullWidth: true },
                React.createElement(TextField, { type: 'number', value: increasePercent, onChange: onCreateAdditionalDiscountDataChange, name: 'increase_percent', label: '\u041F\u0440\u043E\u0446\u0435\u043D\u0442 \u043F\u043E\u0432\u044B\u0448\u0435\u043D\u0438\u044F \u0441\u043A\u0438\u0434\u043A\u0438', variant: 'outlined' })),
            React.createElement(FormControl, { margin: 'normal', fullWidth: true },
                React.createElement(TextField, { value: ids, onChange: (e) => setCreateAdditionalDiscountData((prev) => (Object.assign(Object.assign({}, prev), { users_filters: Object.assign(Object.assign({}, prev.users_filters), { ids: e.target.value }) }))), label: '\u0421\u043F\u0438\u0441\u043E\u043A id \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432 (\u0447\u0435\u0440\u0435\u0437 \u0437\u0430\u043F\u044F\u0442\u0443\u044E)', variant: 'outlined' })),
            React.createElement(FormControl, { margin: 'normal', fullWidth: true },
                React.createElement(InputLabel, { id: 'status' }, "\u0421\u0442\u0430\u0442\u0443\u0441 \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432"),
                React.createElement(Select, { id: 'status', name: 'status', value: statuses.length === 0 ? [allClientsValue] : statuses, onChange: onSelectChange, multiple: true, label: '\u0421\u0442\u0430\u0442\u0443\u0441 \u043A\u043B\u0438\u0435\u043D\u0442\u043E\u0432' },
                    React.createElement(MenuItem, { key: allClientsValue, value: allClientsValue, onClick: () => {
                            setCreateAdditionalDiscountData((prev) => {
                                return Object.assign(Object.assign({}, prev), { users_filters: Object.assign(Object.assign({}, prev.users_filters), { statuses: [allClientsValue] }) });
                            });
                        } }, allClientsValue),
                    MarketingLoyaltyAdditionalDiscountsClientStatuses.map((status) => {
                        return (React.createElement(MenuItem, { key: status, value: status }, status));
                    }))),
            React.createElement(FormControlLabel, { sx: { display: "block" }, control: React.createElement(Checkbox, { name: 'enabled', color: 'primary', checked: !!enabled, onChange: onCheckBoxChange }), label: '\u0412\u043A\u043B\u044E\u0447\u0435\u043D\u043E' }),
            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { name: 'only_without_orders', color: 'primary', checked: !!only_without_orders, onChange: onCheckBoxChange }), label: '\u041D\u0435 \u0432\u043A\u043B\u044E\u0447\u0430\u0442\u044C \u0441\u043A\u0438\u0434\u043A\u0443 \u043A\u043B\u0438\u0435\u043D\u0442\u0430\u043C \u0441 \u043E\u0444\u043E\u0440\u043C\u043B\u0435\u043D\u043D\u044B\u043C \u0437\u0430\u043A\u0430\u0437\u043E\u043C' })),
        React.createElement(DialogActions, null,
            React.createElement(LoadingButton, { loading: isSaving, variant: 'contained', color: 'success', onClick: onSave }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
            React.createElement(Button, { variant: 'contained', color: 'error', onClick: onAddDialogClose }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"))));
};
export { MarketingLoyaltyAdditionalDiscountsDialogForm };
