import { TableCell, TableRow } from "@mui/material";
import { common, grey } from "@mui/material/colors";
import React from "react";
import { MarketingLoyaltyDiscountsTableBodyPointsCell } from "../MarketingLoyaltyDiscountsTableBodyPointsCell/MarketingLoyaltyDiscountsTableBodyPointsCell";
const MarketingLoyaltyDiscountsTableBodyPointsRow = ({ discount, isEven, }) => {
    const { pointDiscounts } = discount;
    const background = isEven ? grey[200] : common.white;
    return (React.createElement(TableRow, { sx: { background } },
        React.createElement(TableCell, { align: 'center' }, "\u0417\u0430 \u0441\u043A\u043E\u043B\u044C\u043A\u043E \u0431\u0430\u043B\u043B\u043E\u0432 \u043F\u043E\u0432\u044B\u0448\u0430\u0435\u043C"),
        pointDiscounts.map((pointDiscount) => {
            const { id } = pointDiscount;
            return (React.createElement(MarketingLoyaltyDiscountsTableBodyPointsCell, { key: id, pointDiscount: pointDiscount, discountId: discount.id }));
        })));
};
export { MarketingLoyaltyDiscountsTableBodyPointsRow };
