import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
const ProtectedRoute = ({ isAllowed, redirectPath = "/" }) => {
    const dispatch = useAppDispatch();
    if (!isAllowed) {
        dispatch(showEventSnack({ isShowed: true, type: "err", messageValue: "У вас нет прав для доступа к странице" }));
        return React.createElement(Navigate, { to: redirectPath, replace: true });
    }
    return React.createElement(Outlet, null);
};
export { ProtectedRoute };
