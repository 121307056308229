import { Paper, TableContainer } from "@mui/material";
import React from "react";
import { MarketingLoyaltyDiscountsTableHead, MarketingLoyaltyDiscountsTableBody } from "../../components";
import { MarketingLoyaltyDiscountsTableStyle } from "./MarketingLoyaltyDiscountsTable.styles";
const MarketingLoyaltyDiscountsTable = ({ discounts }) => {
    return (React.createElement(TableContainer, { component: Paper },
        React.createElement(MarketingLoyaltyDiscountsTableStyle, null,
            React.createElement(MarketingLoyaltyDiscountsTableHead, null),
            React.createElement(MarketingLoyaltyDiscountsTableBody, { discounts: discounts }))));
};
export { MarketingLoyaltyDiscountsTable };
