import { useState } from "react";
const useMultipleSelect = () => {
    const [values, setValues] = useState([]);
    const onChange = (event) => {
        const { target: { value }, } = event;
        setValues(typeof value === "string" ? value.split(",") : value);
    };
    return { values, onChange };
};
export { useMultipleSelect };
