import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, FormControl, InputLabel, MenuItem, Select, Typography, } from "@mui/material";
import { DateRange } from "Components/DateRange";
import React from "react";
import { useAccordion } from "../hooks";
import { certificateDesignIds, certificateStatuses } from "../data";
const CertificatesFilters = ({ statusIds, onStatusIdsChange, designIds, onDesignIdsChange, activationDateFrom, activationDateTo, activationDateErrFrom, activationDateErrTo, setActivationDateFrom, setActivationDateTo, setActivationDateErrFrom, setActivationDateErrTo, activeUntilDateFrom, activeUntilDateTo, activeUntilDateErrFrom, activeUntilDateErrTo, setActiveUntilDateFrom, setActiveUntilDateTo, setActiveUntilDateErrFrom, setActiveUntilDateErrTo, }) => {
    const { isExpanded, onAccordionDisplayChange } = useAccordion();
    return (React.createElement(Accordion, { sx: { mt: "1rem" }, expanded: isExpanded, onChange: onAccordionDisplayChange },
        React.createElement(AccordionSummary, { expandIcon: React.createElement(ExpandMore, null) },
            React.createElement(Typography, null, "\u0424\u0438\u043B\u044C\u0442\u0440\u044B")),
        React.createElement(AccordionDetails, null,
            React.createElement("div", { style: { display: "flex", justifyContent: "center" } },
                React.createElement(FormControl, { sx: { mr: "2rem", width: "50%" } },
                    React.createElement(InputLabel, { id: 'status' }, "\u0421\u0442\u0430\u0442\u0443\u0441"),
                    React.createElement(Select, { id: 'status', name: 'status', value: statusIds, onChange: onStatusIdsChange, multiple: true, label: '\u0421\u0442\u0430\u0442\u0443\u0441' }, certificateStatuses.map((certificateStatus) => {
                        const { id, name } = certificateStatus;
                        return (React.createElement(MenuItem, { key: id, value: id }, name));
                    }))),
                React.createElement(FormControl, { sx: { width: "50%" } },
                    React.createElement(InputLabel, { id: 'status' }, "ID \u0434\u0438\u0437\u0430\u0439\u043D\u0430"),
                    React.createElement(Select, { id: 'status', name: 'status', value: designIds, onChange: onDesignIdsChange, multiple: true, label: 'ID \u0434\u0438\u0437\u0430\u0439\u043D\u0430' }, certificateDesignIds.map((certificateDesignId) => {
                        const { id, name } = certificateDesignId;
                        return (React.createElement(MenuItem, { key: id, value: id }, name));
                    })))),
            React.createElement("div", { style: { display: "flex", marginTop: "2rem", justifyContent: "space-between", gap: "2rem" } },
                React.createElement(DateRange, { dateFrom: activationDateFrom, dateTo: activationDateTo, dateErrFrom: activationDateErrFrom, dateErrTo: activationDateErrTo, setDateFrom: setActivationDateFrom, setDateTo: setActivationDateTo, setDateErrFrom: setActivationDateErrFrom, setDateErrTo: setActivationDateErrTo, dateRangeHandler: () => { }, title: '\u0414\u0430\u0442\u0430 \u0430\u043A\u0442\u0438\u0432\u0430\u0446\u0438\u0438', showFindButton: false }),
                React.createElement(DateRange, { dateFrom: activeUntilDateFrom, dateTo: activeUntilDateTo, dateErrFrom: activeUntilDateErrFrom, dateErrTo: activeUntilDateErrTo, setDateFrom: setActiveUntilDateFrom, setDateTo: setActiveUntilDateTo, setDateErrFrom: setActiveUntilDateErrFrom, setDateErrTo: setActiveUntilDateErrTo, dateRangeHandler: () => { }, title: '\u0414\u0430\u0442\u0430 \u043F\u0440\u0435\u043A\u0440\u0430\u0449\u0435\u043D\u0438\u044F \u0430\u043A\u0442\u0438\u0432\u0430\u0446\u0438\u0438', showFindButton: false })))));
};
export { CertificatesFilters };
