import React from "react";
import { CircularProgress, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, } from "@mui/material";
import { brown } from "@mui/material/colors";
import { CertificateListItem } from "Pages/Certificates/components/CertificateListItem";
export const CertificateList = ({ certificates, isLoading }) => {
    if (isLoading && certificates.length === 0) {
        return React.createElement(CircularProgress, { value: 10, className: 'defaultLoader' });
    }
    if (certificates.length === 0) {
        return (React.createElement(Typography, { sx: { mt: "5rem" }, align: 'center' }, "\u0421\u0435\u0440\u0442\u0438\u0444\u0438\u043A\u0430\u0442\u043E\u0432 \u043D\u0435 \u043D\u0430\u0439\u0434\u0435\u043D\u043E. \u0418\u0437\u043C\u0435\u043D\u0438\u0442\u0435 \u0434\u0430\u0442\u0443 \u0438\u043B\u0438 \u0444\u0438\u043B\u044C\u0442\u0440\u044B."));
    }
    return (React.createElement(TableContainer, { component: Paper, sx: { mt: "1rem" } },
        React.createElement(Table, null,
            React.createElement(TableHead, { sx: { background: brown[500] } },
                React.createElement(TableRow, null,
                    React.createElement(TableCell, { sx: { color: "white" } }),
                    React.createElement(TableCell, { sx: { color: "white" } }, "\u2116"),
                    React.createElement(TableCell, { sx: { color: "white" } }, "\u041A\u0430\u0440\u0442\u0430"),
                    React.createElement(TableCell, { sx: { color: "white" } }, "\u041A\u043B\u0438\u0435\u043D\u0442"),
                    React.createElement(TableCell, { sx: { color: "white" } }, "\u0421\u0442\u0430\u0442\u0443\u0441"),
                    React.createElement(TableCell, { sx: { color: "white" } }, "\u0421\u0443\u043C\u043C\u0430"),
                    React.createElement(TableCell, { sx: { color: "white" } }, "\u0421\u0440\u043E\u043A \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F"))),
            React.createElement(TableBody, null, certificates.map((certificate) => (React.createElement(CertificateListItem, { key: certificate.id, certificate: certificate })))))));
};
