import { Check, Close, Edit } from "@mui/icons-material";
import { CircularProgress, IconButton, TableCell, TextField } from "@mui/material";
import React, { useContext, useRef, useState } from "react";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { MarketingLoyaltyDiscountsContext } from "Src/context/MarketingLoyaltyDiscountsContextProvider";
import { sendHttpReq } from "Utils/sendHttpReq";
const MarketingLoyaltyDiscountsTableBodyPercentCell = ({ pointDiscount, discountId, }) => {
    const { discountPercent, id } = pointDiscount;
    const dispatch = useAppDispatch();
    const { discounts, setDiscounts } = useContext(MarketingLoyaltyDiscountsContext);
    const initialDiscounts = useRef(discounts);
    const [isEdit, setIsEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const onCellChange = (e) => {
        setDiscounts((prevDiscounts) => {
            return prevDiscounts.map((prevDiscount) => {
                if (prevDiscount.id === discountId) {
                    const newPointDiscounts = prevDiscount.pointDiscounts.map((pointDiscount) => {
                        if (pointDiscount.id === id) {
                            return Object.assign(Object.assign({}, pointDiscount), { [e.target.id]: e.target.value });
                        }
                        return pointDiscount;
                    });
                    return Object.assign(Object.assign({}, prevDiscount), { pointDiscounts: newPointDiscounts });
                }
                return prevDiscount;
            });
        });
    };
    const onSave = () => {
        setIsLoading(true);
        sendHttpReq({ method: "post", url: "/discount-point-settings/save", data: discounts })
            .then(({ data }) => {
            setDiscounts(data);
            initialDiscounts.current = data;
            setIsEdit(false);
        })
            .catch(() => {
            dispatch(showEventSnack({ isShowed: true, messageValue: "Не удалось обновить ячейку", type: "err" }));
        })
            .finally(() => {
            setIsLoading(false);
            dispatch(showEventSnack({ isShowed: true, messageValue: "Ячейка успешно обновлена", type: "success" }));
        });
    };
    const onClose = () => {
        setIsEdit(false);
        setDiscounts((prevDiscounts) => {
            return prevDiscounts.map((prevDiscount) => {
                if (prevDiscount.id === discountId) {
                    const newPointDiscounts = prevDiscount.pointDiscounts.map((pointDiscount) => {
                        var _a;
                        if (pointDiscount.id === id) {
                            const initialPointDiscount = (_a = initialDiscounts.current
                                .find((item) => item.id === discountId)) === null || _a === void 0 ? void 0 : _a.pointDiscounts.find((item) => item.id === id);
                            if (initialPointDiscount) {
                                return initialPointDiscount;
                            }
                        }
                        return pointDiscount;
                    });
                    return Object.assign(Object.assign({}, prevDiscount), { pointDiscounts: newPointDiscounts });
                }
                return prevDiscount;
            });
        });
    };
    return (React.createElement(TableCell, { align: 'center' },
        React.createElement("div", { style: { display: "flex", alignItems: "center", justifyContent: "center", width: "180px" } }, isEdit ? (React.createElement(React.Fragment, null,
            React.createElement(TextField, { value: discountPercent, onChange: onCellChange, autoFocus: true, margin: 'dense', id: 'discountPercent', label: '\u041F\u0440\u043E\u0446\u0435\u043D\u0442', variant: 'filled', size: 'small', sx: { width: "60px" } }),
            React.createElement(IconButton, { color: 'success', size: 'small', onClick: onSave }, isLoading ? React.createElement(CircularProgress, { size: '15px' }) : React.createElement(Check, { fontSize: 'small' })),
            React.createElement(IconButton, { color: 'error', size: 'small', onClick: onClose },
                React.createElement(Close, { fontSize: 'small' })))) : (React.createElement(React.Fragment, null,
            discountPercent,
            React.createElement(IconButton, { color: 'primary', size: 'small', onClick: () => setIsEdit(true) },
                React.createElement(Edit, { fontSize: 'small' })))))));
};
export { MarketingLoyaltyDiscountsTableBodyPercentCell };
