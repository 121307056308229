import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "./useQuery";
const useTabs = (defaultTab, isSubTab = false) => {
    var _a;
    const query = useQuery();
    const [searchParams, setSearchParams] = useSearchParams();
    const keyName = isSubTab ? "subtab" : "tab";
    const [activeTab, setActiveTab] = useState((_a = query.get(keyName)) !== null && _a !== void 0 ? _a : defaultTab);
    const tabHandler = (_, newTabValue) => {
        setActiveTab(newTabValue);
        if (typeof newTabValue === "string") {
            if (!isSubTab) {
                searchParams.delete("subtab");
            }
            searchParams.set(keyName, newTabValue);
            setSearchParams(searchParams);
        }
    };
    return [activeTab, tabHandler];
};
export { useTabs };
