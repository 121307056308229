import { uuid4 } from "@sentry/utils";
export const newAchievement = {
    id: uuid4(),
    name: "",
    description: "",
    enabled: false,
    type: "register",
    activityPeriod: null,
    pointsCount: null,
    steps: [],
    settings: null,
};
