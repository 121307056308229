import { TableCell, TableHead, TableRow } from "@mui/material";
import { brown } from "@mui/material/colors";
import React from "react";
const BasicTableHead = ({ tableHeadCells }) => {
    return (React.createElement(TableHead, { sx: { background: brown[500] } },
        React.createElement(TableRow, null, tableHeadCells.map((tableHeaderCell, index) => {
            const { name } = tableHeaderCell;
            return (React.createElement(TableCell, { sx: { color: "white" }, key: index, align: 'center' }, name));
        }))));
};
export { BasicTableHead };
