import React from "react";
import { Link } from "react-router-dom";
import { Link as MuiLink } from "@mui/material";
const NavBarLinkWrapper = ({ url, oldAdminUrl, children }) => {
    const target = url ? "_self" : "_blank";
    if (url) {
        return (React.createElement(Link, { to: url, style: { color: "inherit" } }, children));
    }
    return (React.createElement(MuiLink, { href: oldAdminUrl, color: 'inherit', target: target, rel: 'noopener' }, children));
};
export { NavBarLinkWrapper };
