import { temporaryToken } from "Src/constants";
import { crmApiUrl } from "Src/variables";
import { sendHttpReq } from "Utils/sendHttpReq";
import { useEffect, useState } from "react";
const useGetCompanies = () => {
    const [companiesData, setCompaniesData] = useState([]);
    useEffect(() => {
        sendHttpReq({ method: "get", url: `${crmApiUrl}/courier-delivery/companies`, authHeader: temporaryToken }).then(({ data }) => setCompaniesData(data));
    }, []);
    return [companiesData, setCompaniesData];
};
export { useGetCompanies };
