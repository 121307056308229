import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import React from "react";
import { useTabs, useTitle } from "Src/hooks";
import { MarketingLoyalty } from "./components";
const Marketing = () => {
    useTitle("Маркетинг");
    const [activeTab, tabHandler] = useTabs("loyalty");
    return (React.createElement("div", null,
        React.createElement(TabContext, { value: activeTab },
            React.createElement(TabList, { centered: true, indicatorColor: 'primary', variant: 'fullWidth', onChange: tabHandler },
                React.createElement(Tab, { label: '\u0421\u0438\u0441\u0442\u0435\u043C\u0430 \u043B\u043E\u044F\u043B\u044C\u043D\u043E\u0441\u0442\u0438', value: 'loyalty' })),
            React.createElement(TabPanel, { value: 'loyalty', style: { paddingTop: "0" } },
                React.createElement(MarketingLoyalty, null)))));
};
export { Marketing };
