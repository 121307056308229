import { Typography } from "@mui/material";
import React from "react";
import { isBrandsSettings, isDatesSettings, isPercentSettings, isPurchasesSettings, } from "Src/types/marketing/marketingAchievements";
const MarketingLoyaltyCardSettings = ({ settings, brands }) => {
    var _a, _b, _c;
    if (settings == null)
        return null;
    if (isBrandsSettings(settings)) {
        const brandName = (_a = brands.find((brand) => brand.id === String(settings.brandId))) === null || _a === void 0 ? void 0 : _a.name;
        return (React.createElement(Typography, { variant: 'subtitle1' },
            "\u0411\u0440\u0435\u043D\u0434:",
            " ",
            React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, brandName || "Нет бренда")));
    }
    if (isPercentSettings(settings)) {
        return (React.createElement(Typography, { variant: 'subtitle1' },
            "\u0422\u0440\u0435\u0431\u0443\u0435\u043C\u044B\u0439 % \u0432\u044B\u043A\u0443\u043F\u0430:",
            " ",
            React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, (_b = settings.needAcceptPercent) !== null && _b !== void 0 ? _b : 0)));
    }
    if (isDatesSettings(settings)) {
        return (React.createElement(React.Fragment, null,
            React.createElement(Typography, { variant: 'subtitle1' }, "\u041D\u043E\u0447\u043D\u043E\u0435 \u0432\u0440\u0435\u043C\u044F:"),
            React.createElement(Typography, { variant: 'subtitle1' },
                "\u041E\u0442:",
                " ",
                React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, settings.timeFrom || "Неизвестно")),
            React.createElement(Typography, { variant: 'subtitle1' },
                "\u0414\u043E:",
                " ",
                React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, settings.timeTo || "Неизвестно"))));
    }
    if (isPurchasesSettings(settings)) {
        return (React.createElement(Typography, { variant: 'subtitle1' },
            "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043F\u043E\u043A\u0443\u043F\u043E\u043A:",
            " ",
            React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, (_c = settings.purchasesCount) !== null && _c !== void 0 ? _c : 0)));
    }
    return null;
};
export { MarketingLoyaltyCardSettings };
