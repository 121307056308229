import { TableCell, TableHead, TableRow } from "@mui/material";
import { teal } from "@mui/material/colors";
import React from "react";
const tableHeadCells = [{ name: "процент выкупа в год" }, { name: "размер скидки", colSpan: 6 }];
const MarketingLoyaltyDiscountsTableHead = () => {
    return (React.createElement(TableHead, { sx: { background: teal[100] }, style: { fontSize: "2rem" } },
        React.createElement(TableRow, null, tableHeadCells.map((tableHeadCell, index) => {
            const { name, colSpan } = tableHeadCell;
            return (React.createElement(TableCell, { key: index, colSpan: colSpan, align: 'center', sx: { textTransform: "capitalize" } }, name));
        }))));
};
export { MarketingLoyaltyDiscountsTableHead };
