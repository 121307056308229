var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { Delete, Edit, Remove } from "@mui/icons-material";
import { Dialog, FormControl, FormControlLabel, IconButton, Radio, RadioGroup, TableCell, TableRow } from "@mui/material";
import { dateStringToLocal, sendHttpReq } from "Utils/index";
import React, { useState } from "react";
import { crmApiUrl } from "Src/variables";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { OrderCourierDeliveriesForm } from "../OrderCourierDeliveriesForm";
import { temporaryToken } from "Src/constants";
import { serviceOrderStatuses } from "../OrderCourierDeliveriesForm/constants";
const OrdersCourierDeliveriesTableRow = ({ delivery, onUpdate, onDelete, couriers, companiesData, }) => {
    var _a;
    const dispatch = useAppDispatch();
    const [showEditModal, setShowEditModal] = useState(false);
    const onEditModalClose = () => setShowEditModal(false);
    const [isProcessing, setIsProcessing] = useState(false);
    const updateDelivery = (data, shouldRefetch = true) => __awaiter(void 0, void 0, void 0, function* () {
        var _b, _c, _d;
        if (isProcessing)
            return;
        setIsProcessing(true);
        try {
            const res = yield sendHttpReq({
                method: "put",
                url: `${crmApiUrl}/courier-deliveries/` + delivery.id,
                authHeader: temporaryToken,
                data: {
                    courier_id: data.courier.id,
                    address_from: data.addressFrom,
                    address_to: data.addressTo,
                    description: data.description,
                    date: data.date,
                    delivery_coefficient: data.coefficient.replace("x", ""),
                    company_id: (_b = data === null || data === void 0 ? void 0 : data.companyData) === null || _b === void 0 ? void 0 : _b.id,
                    city_id: (_c = data === null || data === void 0 ? void 0 : data.cityData) === null || _c === void 0 ? void 0 : _c.id,
                    status: (_d = data === null || data === void 0 ? void 0 : data.status) === null || _d === void 0 ? void 0 : _d.id,
                    customer_name: data.name,
                    customer_phone: data.phoneNumber,
                    customer_price: data.price,
                },
            });
            if (shouldRefetch) {
                onUpdate();
            }
            else {
                onUpdate(false, res.data);
            }
            setShowEditModal(false);
            dispatch(showEventSnack({ isShowed: true, messageValue: "Поручение успешно отредактировано", type: "success" }));
            return res.data;
        }
        finally {
            setIsProcessing(false);
        }
    });
    const onRemove = () => {
        if (!confirm(`Удалить Поручение ${delivery.id}?`))
            return;
        sendHttpReq({
            method: "delete",
            url: `${crmApiUrl}/courier-deliveries/` + delivery.id,
            authHeader: temporaryToken,
        }).then(({ data }) => {
            onDelete();
            dispatch(showEventSnack({ isShowed: true, messageValue: "Поручение успешно удалено", type: "success" }));
        });
    };
    const onCancel = () => setShowEditModal(false);
    const [statusId, setStatusId] = useState(delivery.status.id);
    const onStatusChange = (event) => {
        const { addressFrom, addressTo, city, delivery_coefficient, company, courier, date, description, customer_name, customer_phone, customer_price, } = delivery;
        const companyData = companiesData.find((companyData) => companyData.name === company);
        const status = serviceOrderStatuses.find((serviceOrderStatus) => serviceOrderStatus.id === event.target.value);
        updateDelivery({
            addressFrom,
            addressTo,
            cityData: city,
            coefficient: String(delivery_coefficient),
            companyData: companyData,
            courier,
            date,
            description,
            name: customer_name,
            phoneNumber: customer_phone,
            price: customer_price,
            status: status,
        }, false).then(() => setStatusId(event.target.value));
    };
    const renderPhone = () => {
        var _a, _b, _c, _d;
        if (delivery.customer_phone) {
            return `+${delivery.customer_phone}`;
        }
        if (((_b = (_a = delivery.creator) === null || _a === void 0 ? void 0 : _a.phone) === null || _b === void 0 ? void 0 : _b.code) && ((_d = (_c = delivery.creator) === null || _c === void 0 ? void 0 : _c.phone) === null || _d === void 0 ? void 0 : _d.body)) {
            return `+${delivery.creator.phone.code}${delivery.creator.phone.body}`;
        }
        return null;
    };
    const tableCells = [
        delivery.id,
        delivery.customer_name || delivery.creator.name,
        renderPhone(),
        delivery.company,
        (_a = delivery === null || delivery === void 0 ? void 0 : delivery.city) === null || _a === void 0 ? void 0 : _a.name,
        delivery.customer_price,
        dateStringToLocal(delivery.date),
        delivery.addressFrom,
        delivery.addressTo,
        delivery.courier.name,
        React.createElement("div", { style: { minWidth: "14rem" } },
            React.createElement(FormControl, null,
                React.createElement(RadioGroup, { name: 'status', value: statusId, onChange: onStatusChange }, serviceOrderStatuses.map((serviceOrderStatus) => {
                    const { id, name } = serviceOrderStatus;
                    return (React.createElement(FormControlLabel, { disabled: name === "на доставке у курьера" && !delivery.courier.id, key: id, value: id, control: React.createElement(Radio, null), label: name }));
                })))),
        `${delivery.delivery_price || ""} x${delivery.delivery_coefficient}`,
        delivery.description,
        React.createElement(React.Fragment, null,
            React.createElement(IconButton, { onClick: () => setShowEditModal(true), color: 'primary', title: '\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C' },
                React.createElement(Edit, null)),
            React.createElement(IconButton, { onClick: onRemove, color: 'error', title: '\u0423\u0434\u0430\u043B\u0438\u0442\u044C' },
                React.createElement(Delete, null))),
    ];
    return (React.createElement(React.Fragment, null,
        React.createElement(TableRow, null, tableCells.map((tableCell, index) => (React.createElement(TableCell, { key: index, align: 'center' }, tableCell || React.createElement(Remove, null))))),
        React.createElement(Dialog, { open: showEditModal, onClose: onEditModalClose, fullWidth: true },
            React.createElement(OrderCourierDeliveriesForm, { delivery: delivery, couriers: couriers, updateDelivery: updateDelivery, onCancel: onCancel, companiesData: companiesData }))));
};
export { OrdersCourierDeliveriesTableRow };
