const certificateStatuses = [
    { id: "0", name: "создан" },
    { id: "1", name: "ждёт наступления даты активации" },
    { id: "3", name: "активирован" },
    { id: "5", name: "истёк" },
];
const certificateDesignIds = [
    { id: "0", name: "0" },
    { id: "1", name: "1" },
    { id: "2", name: "2" },
    { id: "3", name: "3" },
];
export { certificateStatuses, certificateDesignIds };
