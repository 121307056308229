import { TextField } from "@mui/material";
import React from "react";
import { isStepPurchasesCount, isStepPurchasesProductsCountSettings, } from "Src/types/marketing/marketingAchievements";
const MarketingLoyaltyFormStepsOptionalInputs = ({ type, settings, onChangeStepSettings, }) => {
    if (type === "brandPurchase" || type === "accept") {
        return (React.createElement(TextField, { type: 'number', id: 'purchasesProductsCount', value: isStepPurchasesProductsCountSettings(settings) ? settings.purchasesProductsCount : 0, onChange: onChangeStepSettings, margin: 'dense', label: '\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043A\u0443\u043F\u043B\u0435\u043D\u043D\u044B\u0445 \u0442\u043E\u0432\u0430\u0440\u043E\u0432', fullWidth: true, variant: 'outlined' }));
    }
    if (type === "acceptInRow" || type === "pvzDelivery" || type === "weekendPurchase" || type === "nightPurchase") {
        return (React.createElement(TextField, { type: 'number', id: 'purchasesCount', value: isStepPurchasesCount(settings) ? settings.purchasesCount : 0, onChange: onChangeStepSettings, margin: 'dense', label: '\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043F\u043E\u043A\u0443\u043F\u043E\u043A', fullWidth: true, variant: 'outlined' }));
    }
    return null;
};
export { MarketingLoyaltyFormStepsOptionalInputs };
