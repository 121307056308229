import { LoadingButton } from "@mui/lab";
import { Autocomplete, Button, CircularProgress, DialogActions, DialogContent, TextField, Typography, } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { temporaryToken } from "Src/constants";
import { crmApiUrl } from "Src/variables";
import { formatPhoneNumber, undoFormatPhoneNumber, sendHttpReq } from "Utils/index";
import React, { useEffect, useState } from "react";
import { serviceOrderStatuses } from "./constants";
const OrderCourierDeliveriesForm = ({ delivery, couriers, updateDelivery, onCancel, companiesData, }) => {
    const dispatch = useAppDispatch();
    const [courier, setCourier] = useState((delivery === null || delivery === void 0 ? void 0 : delivery.courier) || null);
    const [formData, setFormData] = useState(null);
    const [isFormDataLoading, setIsFormDataLoading] = useState(true);
    const [cityData, setCityData] = useState(delivery.city || "");
    const [companyData, setCompanyData] = useState(undefined);
    useEffect(() => {
        const company = companiesData.find((companyData) => companyData.name === delivery.company);
        if (company) {
            setCompanyData(company);
        }
    }, [companiesData]);
    const [status, setStatus] = useState(delivery.status || "");
    const [addressFrom, setAddressFrom] = useState((delivery === null || delivery === void 0 ? void 0 : delivery.addressFrom) || "");
    const [addressTo, setAddressTo] = useState((delivery === null || delivery === void 0 ? void 0 : delivery.addressTo) || "");
    const [description, setDescription] = useState((delivery === null || delivery === void 0 ? void 0 : delivery.description) || "");
    const [date, setDate] = useState(delivery ? new Date(Date.parse(delivery.date)) : new Date());
    const [dateErr, setDateErr] = useState(false);
    const [name, setName] = useState(delivery.customer_name || delivery.creator.name || "");
    const getInitialPhoneNumber = () => {
        var _a, _b, _c, _d;
        if (delivery.customer_phone) {
            return delivery.customer_phone;
        }
        if (((_b = (_a = delivery.creator) === null || _a === void 0 ? void 0 : _a.phone) === null || _b === void 0 ? void 0 : _b.code) && ((_d = (_c = delivery.creator) === null || _c === void 0 ? void 0 : _c.phone) === null || _d === void 0 ? void 0 : _d.body)) {
            return delivery.creator.phone.code + delivery.creator.phone.body;
        }
        return "";
    };
    const [phoneNumber, setPhoneNumber] = useState(getInitialPhoneNumber());
    const [price, setPrice] = useState(delivery.customer_price || "");
    const [coefficient, setCoefficient] = useState(`x${(delivery === null || delivery === void 0 ? void 0 : delivery.delivery_coefficient) || 1}` || "");
    const [isProcessing, setIsProcessing] = useState(false);
    useEffect(() => {
        const promises = [
            sendHttpReq({ method: "get", url: `${crmApiUrl}/courier-delivery/cities`, authHeader: temporaryToken }),
            sendHttpReq({ method: "get", url: `${crmApiUrl}/courier/form-data`, authHeader: temporaryToken }),
        ];
        Promise.all(promises)
            .then(([{ data: citiesData }, { data: coefficientsAndTypesData }]) => setFormData({ citiesData, coefficientsAndTypesData }))
            .finally(() => setIsFormDataLoading(false));
    }, []);
    if (isFormDataLoading) {
        return (React.createElement(DialogContent, { sx: { minHeight: "6rem", position: "relative" } },
            React.createElement(CircularProgress, { className: 'defaultLoader' })));
    }
    if (formData == null) {
        return (React.createElement(Typography, { align: 'center', variant: 'h6', component: 'h1', sx: { p: "2rem" } }, "\u041D\u0435 \u0443\u0434\u0430\u043B\u043E\u0441\u044C \u0437\u0430\u0433\u0440\u0443\u0437\u0438\u0442\u044C \u0434\u0430\u043D\u043D\u044B\u0435 \u0434\u043B\u044F \u0444\u043E\u0440\u043C\u044B"));
    }
    const { citiesData, coefficientsAndTypesData: { coefficient: coefficients }, } = formData;
    const onSave = () => {
        if (!courier) {
            dispatch(showEventSnack({ isShowed: true, messageValue: "Выберите курьера", type: "err" }));
            return;
        }
        setIsProcessing(true);
        if (courier && date && companiesData) {
            updateDelivery({
                courier,
                date,
                addressFrom,
                addressTo,
                description,
                coefficient,
                status,
                cityData,
                companyData,
                name,
                phoneNumber,
                price,
            }).finally(() => setIsProcessing(false));
        }
    };
    return (React.createElement(React.Fragment, null,
        React.createElement(DialogContent, null,
            React.createElement(Typography, { variant: 'h5', align: 'center', sx: { mb: "1rem" } }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u043F\u043E\u0440\u0443\u0447\u0435\u043D\u0438\u0435"),
            React.createElement("div", { style: { display: "flex", flexDirection: "column", gap: "1rem" } },
                React.createElement(TextField, { fullWidth: true, label: '\u0424\u0418\u041E \u0438\u043D\u0438\u0446\u0438\u0430\u0442\u043E\u0440\u0430', variant: 'outlined', size: 'small', value: name, onChange: (e) => setName(e.target.value) }),
                React.createElement(TextField, { fullWidth: true, label: '\u041D\u043E\u043C\u0435\u0440 \u0442\u0435\u043B\u0435\u0444\u043E\u043D\u0430 \u0438\u043D\u0438\u0446\u0438\u0430\u0442\u043E\u0440\u0430', variant: 'outlined', size: 'small', value: formatPhoneNumber(phoneNumber), onChange: (e) => setPhoneNumber(undoFormatPhoneNumber(e.target.value)), InputProps: {
                        startAdornment: "+",
                    } }),
                React.createElement(Autocomplete, { value: companyData, onChange: (e, value) => setCompanyData(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { fullWidth: true, variant: 'outlined', label: '\u041A\u043E\u043C\u043F\u0430\u043D\u0438\u044F' })), disableClearable: true, options: companiesData, getOptionLabel: (option) => option.name }),
                React.createElement(Autocomplete, { value: cityData, onChange: (e, value) => setCityData(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { fullWidth: true, variant: 'outlined', label: '\u0413\u043E\u0440\u043E\u0434' })), disableClearable: true, options: citiesData, getOptionLabel: (option) => (option === null || option === void 0 ? void 0 : option.name) || "(Не выбрано)" }),
                React.createElement(TextField, { fullWidth: true, label: '\u0421\u0442\u043E\u0438\u043C\u043E\u0441\u0442\u044C \u0434\u043B\u044F \u0437\u0430\u043A\u0430\u0437\u0447\u0438\u043A\u0430', variant: 'outlined', size: 'small', value: price, onChange: (e) => setPrice(e.target.value) }),
                React.createElement(DatePicker, { inputFormat: 'yyyy-MM-dd', mask: '____-__-__', label: '\u0414\u0430\u0442\u0430', value: date, onChange: (date) => date && setDate(date), onError: (err) => setDateErr(!!err), renderInput: (props) => React.createElement(TextField, Object.assign({ helperText: dateErr ? "Некорректная дата" : "" }, props)) }),
                React.createElement(TextField, { fullWidth: true, label: '\u041E\u0442\u043A\u0443\u0434\u0430 (\u0430\u0434\u0440\u0435\u0441)', variant: 'outlined', size: 'small', value: addressFrom, onChange: (e) => setAddressFrom(e.target.value) }),
                React.createElement(TextField, { fullWidth: true, label: '\u041A\u0443\u0434\u0430 (\u0430\u0434\u0440\u0435\u0441)', variant: 'outlined', size: 'small', value: addressTo, onChange: (e) => setAddressTo(e.target.value) }),
                React.createElement(Autocomplete, { value: courier || undefined, onChange: (e, value) => setCourier(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { fullWidth: true, variant: 'outlined', label: '\u041A\u0443\u0440\u044C\u0435\u0440' })), disableClearable: true, options: couriers, getOptionLabel: (option) => (option === null || option === void 0 ? void 0 : option.name) || "(Не выбрано)" }),
                React.createElement(Autocomplete, { value: status, onChange: (e, value) => setStatus(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { fullWidth: true, variant: 'outlined', label: '\u0421\u0442\u0430\u0442\u0443\u0441 \u043F\u043E\u0440\u0443\u0447\u0435\u043D\u0438\u0439' })), disableClearable: true, options: serviceOrderStatuses, getOptionLabel: (option) => option.name, getOptionDisabled: (option) => option.name === "на доставке у курьера" && !(courier === null || courier === void 0 ? void 0 : courier.id) }),
                React.createElement(Autocomplete, { value: coefficient, onChange: (e, value) => setCoefficient(value), renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { fullWidth: true, variant: 'outlined', label: '\u041A\u043E\u044D\u0444\u0444\u0438\u0446\u0438\u0435\u043D\u0442 \u043E\u043F\u043B\u0430\u0442\u044B' })), disableClearable: true, options: Object.values(coefficients) }),
                React.createElement(TextField, { className: 'typografy_inputText', multiline: true, fullWidth: true, variant: 'outlined', label: '\u041A\u043E\u043C\u043C\u0435\u043D\u0442\u0430\u0440\u0438\u0439', rows: 3, value: description, onChange: (e) => setDescription(e.target.value) }))),
        React.createElement(DialogActions, null,
            React.createElement(Button, { disabled: isProcessing, variant: 'text', color: 'error', onClick: onCancel }, "\u041E\u0442\u043C\u0435\u043D\u0430"),
            React.createElement(LoadingButton, { loading: isProcessing, variant: 'contained', color: 'primary', onClick: onSave }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"))));
};
export { OrderCourierDeliveriesForm };
