import { temporaryToken } from "Src/constants";
import { crmApiUrl } from "Src/variables";
import { sendHttpReq } from "Utils/sendHttpReq";
import { useEffect, useState } from "react";
const useGetCouriers = () => {
    const [couriers, setCouriers] = useState([]);
    useEffect(() => {
        sendHttpReq({ method: "get", url: `${crmApiUrl}/couriers`, authHeader: temporaryToken }).then((res) => setCouriers(res.data));
    }, []);
    return [couriers, setCouriers];
};
export { useGetCouriers };
