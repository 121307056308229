export const getQueryVariable = (queryName) => {
    const query = window.location.search.substring(1);
    return getQueryVariableFromQuery(query, queryName);
};
export const getQueryVariableFromUrl = (url, queryName) => {
    const query = url.replace(/^.*\?/, "");
    return getQueryVariableFromQuery(query, queryName);
};
function getQueryVariableFromQuery(query, queryName) {
    const vars = query.split("&");
    for (let i = 0; i < vars.length; i++) {
        const pair = vars[i].split("=");
        if (decodeURIComponent(pair[0]) === queryName) {
            return decodeURIComponent(pair[1]);
        }
    }
    return;
}
