import React from "react";
import { useDatePicker, usePagination, useTitle } from "Src/hooks";
import { Pagination, TextField } from "@mui/material";
import { CertificateList } from "Pages/Certificates/components/CertificateList";
import { useCertificateSearch, useGetCertificates, useMultipleSelect } from "./hooks";
import { CertificatesFilters } from "./components";
const Certificates = () => {
    useTitle("Сертификаты");
    const { values: statusIds, onChange: onStatusIdsChange } = useMultipleSelect();
    const { values: designIds, onChange: onDesignIdsChange } = useMultipleSelect();
    const { dateFrom: activationDateFrom, dateErrFrom: activationDateErrFrom, dateTo: activationDateTo, dateErrTo: activationDateErrTo, setDateErrFrom: setActivationDateErrFrom, setDateErrTo: setActivationDateErrTo, setDateFrom: setActivationDateFrom, setDateTo: setActivationDateTo, } = useDatePicker();
    const { dateFrom: activeUntilDateFrom, dateErrFrom: activeUntilDateErrFrom, dateTo: activeUntilDateTo, dateErrTo: activeUntilDateErrTo, setDateErrFrom: setActiveUntilDateErrFrom, setDateErrTo: setActiveUntilDateErrTo, setDateFrom: setActiveUntilDateFrom, setDateTo: setActiveUntilDateTo, } = useDatePicker();
    const { searchQuery, debouncedSearchQuery, onSearchChange } = useCertificateSearch();
    const { currentPage, onCurrentPageChange } = usePagination();
    const { isLoading, certificates, pagesCount } = useGetCertificates({
        currentPage,
        debouncedSearchQuery,
        statusIds,
        designIds,
        activationDateFrom,
        activationDateTo,
        activeUntilDateFrom,
        activeUntilDateTo,
    });
    return (React.createElement("div", { className: 'defaultPageWrapper' },
        React.createElement(TextField, { label: '\u041F\u043E\u0438\u0441\u043A', value: searchQuery, onChange: onSearchChange, sx: { width: "50%" } }),
        React.createElement(CertificatesFilters, { statusIds: statusIds, onStatusIdsChange: onStatusIdsChange, designIds: designIds, onDesignIdsChange: onDesignIdsChange, activationDateFrom: activationDateFrom, activationDateTo: activationDateTo, activationDateErrFrom: activationDateErrFrom, activationDateErrTo: activationDateErrTo, setActivationDateErrFrom: setActivationDateErrFrom, setActivationDateErrTo: setActivationDateErrTo, setActivationDateFrom: setActivationDateFrom, setActivationDateTo: setActivationDateTo, activeUntilDateFrom: activeUntilDateFrom, activeUntilDateTo: activeUntilDateTo, activeUntilDateErrFrom: activeUntilDateErrFrom, activeUntilDateErrTo: activeUntilDateErrTo, setActiveUntilDateErrFrom: setActiveUntilDateErrFrom, setActiveUntilDateErrTo: setActiveUntilDateErrTo, setActiveUntilDateFrom: setActiveUntilDateFrom, setActiveUntilDateTo: setActiveUntilDateTo }),
        !isLoading && pagesCount > 1 && (React.createElement(Pagination, { className: 'customPagination_teal customPagination_top', sx: { mt: "1rem" }, showFirstButton: true, showLastButton: true, page: currentPage, count: pagesCount, onChange: onCurrentPageChange })),
        React.createElement(CertificateList, { certificates: certificates, isLoading: isLoading }),
        !isLoading && pagesCount > 1 && (React.createElement(Pagination, { className: 'customPagination_teal customPagination_bottom', sx: { mt: "1rem" }, showFirstButton: true, showLastButton: true, page: currentPage, count: pagesCount, onChange: onCurrentPageChange }))));
};
export default Certificates;
