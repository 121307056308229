import { Button, FormControl, TextField, Typography } from "@mui/material";
import React from "react";
import { MarketingLoyaltyFormStepsOptionalInputs } from "../MarketingLoyaltyAchievementsFormStepsOptionalInputs/MarketingLoyaltyAchievementsFormStepsOptionalInputs";
const MarketingLoyaltyFormSteps = ({ steps, setAchievementData, type }) => {
    const onChangeStep = (e, id) => {
        setAchievementData((prevAchievementData) => {
            const newSteps = prevAchievementData.steps.map((step) => {
                if (step.id === id) {
                    return Object.assign(Object.assign({}, step), { [e.target.id]: e.target.value });
                }
                return step;
            });
            return Object.assign(Object.assign({}, prevAchievementData), { steps: newSteps });
        });
    };
    const onChangeStepSettings = (id) => (e) => {
        setAchievementData((prevAchievementData) => {
            const newSteps = prevAchievementData.steps.map((step) => {
                if (step.id === id) {
                    return Object.assign(Object.assign({}, step), { settings: { [e.target.id]: +e.target.value } });
                }
                return step;
            });
            return Object.assign(Object.assign({}, prevAchievementData), { steps: newSteps });
        });
    };
    const onAddStep = () => {
        setAchievementData((prevAchievementData) => {
            const newStep = {
                id: "new" + new Date().getTime(),
                name: "",
                description: "",
                pointsCount: 0,
                settings: null,
            };
            return Object.assign(Object.assign({}, prevAchievementData), { steps: [...prevAchievementData.steps, newStep] });
        });
    };
    const onDeleteStep = (id) => {
        if (!confirm("Вы уверены?"))
            return;
        const newSteps = steps.filter((step) => step.id !== id);
        setAchievementData((prevAchievementData) => (Object.assign(Object.assign({}, prevAchievementData), { steps: newSteps })));
    };
    return (React.createElement(React.Fragment, null,
        steps.length > 0 && (React.createElement(Typography, { align: 'center', variant: 'h6', sx: { mt: 2 } }, "\u042D\u0442\u0430\u043F\u044B:")),
        steps.map((step) => {
            const { id, description, name, pointsCount, settings } = step;
            return (React.createElement(FormControl, { sx: { mb: "2rem" }, fullWidth: true, margin: 'dense', key: id },
                React.createElement(TextField, { value: name, onChange: (e) => onChangeStep(e, id), autoFocus: true, margin: 'dense', id: 'name', label: '\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435', fullWidth: true, variant: 'outlined' }),
                React.createElement(TextField, { rows: 2, multiline: true, value: description, onChange: (e) => onChangeStep(e, id), margin: 'dense', id: 'description', label: '\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435', fullWidth: true, variant: 'outlined' }),
                React.createElement(TextField, { type: 'number', value: pointsCount, onChange: (e) => onChangeStep(e, id), margin: 'dense', id: 'pointsCount', label: '\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0431\u0430\u043B\u043B\u043E\u0432', fullWidth: true, variant: 'outlined' }),
                React.createElement(MarketingLoyaltyFormStepsOptionalInputs, { type: type, settings: settings, onChangeStepSettings: onChangeStepSettings(id) }),
                React.createElement(Button, { color: 'error', onClick: () => onDeleteStep(id) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C \u044D\u0442\u0430\u043F")));
        }),
        React.createElement(Button, { onClick: onAddStep }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u044D\u0442\u0430\u043F")));
};
export { MarketingLoyaltyFormSteps };
