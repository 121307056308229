import { LoadingButton } from "@mui/lab";
import { Box, TextField, Typography } from "@mui/material";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { sendHttpReq } from "Utils/sendHttpReq";
import React, { useState } from "react";
const MarketingLoyaltyDiscountsHeader = ({ discounts, setDiscounts }) => {
    const dispatch = useAppDispatch();
    const [percent, setPercent] = useState("0");
    const [onApplyPercentLoading, setOnApplyPercentLoading] = useState(false);
    const onPercentChange = (e) => {
        setPercent(e.target.value);
    };
    const onApplyPercent = () => {
        setOnApplyPercentLoading(true);
        const newDiscounts = discounts.map((discount) => {
            const newPointDiscounts = discount.pointDiscounts.map((pointDiscount) => {
                return Object.assign(Object.assign({}, pointDiscount), { discountPercent: pointDiscount.discountPercent + +percent });
            });
            return Object.assign(Object.assign({}, discount), { pointDiscounts: newPointDiscounts });
        });
        sendHttpReq({ method: "post", url: "/discount-point-settings/save", data: newDiscounts })
            .then(({ data }) => {
            dispatch(showEventSnack({ type: "success", isShowed: true, messageValue: "Процент скидки успешно применен" }));
            setPercent("0");
            setDiscounts(data);
        })
            .finally(() => setOnApplyPercentLoading(false));
    };
    return (React.createElement(Box, { sx: { display: "flex", alignItems: "center", gap: "2rem", my: "2rem" } },
        React.createElement(Typography, { variant: 'h6' }, "\u0418\u0437\u043C\u0435\u043D\u0438\u0442\u044C % \u0441\u043A\u0438\u0434\u043A\u0438 \u043D\u0430 \u0432\u0441\u0435 \u043A\u0430\u0442\u0435\u0433\u043E\u0440\u0438\u0438"),
        React.createElement(TextField, { label: '% \u0441\u043A\u0438\u0434\u043A\u0438', size: 'small', type: 'number', value: percent, onChange: onPercentChange }),
        React.createElement(LoadingButton, { loading: onApplyPercentLoading, variant: 'contained', onClick: onApplyPercent }, "\u041F\u0440\u0438\u043C\u0435\u043D\u0438\u0442\u044C")));
};
export { MarketingLoyaltyDiscountsHeader };
