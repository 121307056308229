import { Autocomplete, FormControl, TextField, Typography } from "@mui/material";
import { TimePicker } from "@mui/x-date-pickers";
import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { isBrandsSettings, isDatesSettings, isPercentSettings, isPurchasesSettings, } from "Src/types/marketing/marketingAchievements";
import { MarketingLoyaltyFormSteps } from "../MarketingLoyaltyAchievementsFormSteps";
const MarketingLoyaltyFormOptionalInputs = ({ type, settings, pointsCount, steps, setAchievementData, brands, onChange, }) => {
    const [timeFrom, setTimeFrom] = useState(isDatesSettings(settings) ? settings.timeFrom : "");
    const [timeTo, setTimeTo] = useState(isDatesSettings(settings) ? settings.timeTo : "");
    const [timeErrFrom, setTimeErrFrom] = useState(false);
    const [timeErrTo, setTimeErrTo] = useState(false);
    useEffect(() => {
        if (type === "nightPurchase") {
            setAchievementData((prevAchievementData) => {
                return Object.assign(Object.assign({}, prevAchievementData), { settings: { timeFrom, timeTo } });
            });
        }
    }, [timeFrom, timeTo]);
    const onAutocompleteChange = (e, value) => {
        setAchievementData((prevAchievementData) => {
            return Object.assign(Object.assign({}, prevAchievementData), { settings: { brandId: +value.id } });
        });
    };
    const onAcceptPercentChange = (e) => {
        const needAcceptPercent = Number(e.target.value);
        setAchievementData((prevAchievementData) => (Object.assign(Object.assign({}, prevAchievementData), { settings: { needAcceptPercent } })));
    };
    const onPurchasesCountChange = (e) => {
        const purchasesCount = Number(e.target.value);
        setAchievementData((prevAchievementData) => (Object.assign(Object.assign({}, prevAchievementData), { settings: { purchasesCount } })));
    };
    switch (type) {
        case "register":
        case "fillLk":
        case "mailingSubscribe": {
            return (React.createElement(TextField, { type: 'number', value: pointsCount || 0, onChange: onChange, margin: 'dense', id: 'pointsCount', label: '\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0431\u0430\u043B\u043B\u043E\u0432', fullWidth: true, variant: 'outlined' }));
        }
        case "brandPurchase": {
            const value = isBrandsSettings(settings)
                ? brands.find((brand) => brand.id === String(settings.brandId))
                : undefined;
            return (React.createElement(React.Fragment, null,
                React.createElement(FormControl, { margin: 'dense', variant: 'outlined', fullWidth: true, size: 'small' },
                    React.createElement(Autocomplete, { value: value, onChange: onAutocompleteChange, renderInput: (params) => React.createElement(TextField, Object.assign({}, params, { fullWidth: true, variant: 'outlined', label: '\u0411\u0440\u0435\u043D\u0434' })), disableClearable: true, options: brands, getOptionLabel: (option) => option.name })),
                React.createElement(MarketingLoyaltyFormSteps, { type: type, steps: steps, setAchievementData: setAchievementData })));
        }
        case "acceptInRow": {
            return (React.createElement(React.Fragment, null,
                React.createElement(TextField, { type: 'number', value: isPercentSettings(settings) ? settings.needAcceptPercent : 0, onChange: onAcceptPercentChange, margin: 'dense', label: '\u0422\u0440\u0435\u0431\u0443\u0435\u043C\u044B\u0439 % \u0432\u044B\u043A\u0443\u043F\u0430', fullWidth: true, variant: 'outlined' }),
                React.createElement(MarketingLoyaltyFormSteps, { type: type, steps: steps, setAchievementData: setAchievementData })));
        }
        case "accept": {
            return React.createElement(MarketingLoyaltyFormSteps, { type: type, steps: steps, setAchievementData: setAchievementData });
        }
        case "pvzDelivery": {
            return React.createElement(MarketingLoyaltyFormSteps, { type: type, steps: steps, setAchievementData: setAchievementData });
        }
        case "weekendPurchase": {
            return React.createElement(MarketingLoyaltyFormSteps, { type: type, steps: steps, setAchievementData: setAchievementData });
        }
        case "nightPurchase": {
            const getValue = (date) => {
                if (date) {
                    const [hours, minutes] = date.split(":");
                    const timeFromDate = new Date().setHours(+hours, +minutes);
                    return timeFromDate;
                }
                return null;
            };
            return (React.createElement(React.Fragment, null,
                React.createElement(FormControl, { sx: { display: "flex" }, margin: 'dense', variant: 'outlined', fullWidth: true, size: 'small' },
                    React.createElement(Typography, { sx: { mb: "1rem" } }, "\u041D\u043E\u0447\u043D\u043E\u0435 \u0432\u0440\u0435\u043C\u044F"),
                    React.createElement(TimePicker, { label: '\u041E\u0442', value: getValue(timeFrom), onChange: (value) => {
                            if (value) {
                                setTimeFrom(format(value, "HH:mm"));
                            }
                        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { sx: { mb: "0.7rem" }, onError: (err) => setTimeErrFrom(!!err), helperText: timeErrFrom ? "Некорректная дата" : "" }))) }),
                    React.createElement(TimePicker, { label: '\u0414\u043E', value: getValue(timeTo), onChange: (value) => {
                            if (value) {
                                setTimeTo(format(value, "HH:mm"));
                            }
                        }, renderInput: (params) => (React.createElement(TextField, Object.assign({}, params, { onError: (err) => setTimeErrTo(!!err), helperText: timeErrTo ? "Некорректная дата" : "" }))) })),
                React.createElement(MarketingLoyaltyFormSteps, { type: type, steps: steps, setAchievementData: setAchievementData })));
        }
        case "firstPurchases": {
            const value = isPurchasesSettings(settings) ? settings.purchasesCount : undefined;
            return (React.createElement(React.Fragment, null,
                React.createElement(TextField, { type: 'number', value: pointsCount || 0, onChange: onChange, margin: 'dense', id: 'pointsCount', label: '\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0431\u0430\u043B\u043B\u043E\u0432', fullWidth: true, variant: 'outlined' }),
                React.createElement(TextField, { type: 'number', id: 'purchasesCount', value: value, onChange: onPurchasesCountChange, margin: 'dense', label: '\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043F\u043E\u043A\u0443\u043F\u043E\u043A', fullWidth: true, variant: 'outlined' })));
        }
        default:
            return null;
    }
};
export { MarketingLoyaltyFormOptionalInputs };
