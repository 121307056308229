import { CardActions, ListItem } from "@mui/material";
import styled from "styled-components";
const MarketingLoyaltyListItem = styled(ListItem) `
  padding: 0 0 3rem 0;
  width: 400px;
  position: relative;
  background-color: whitesmoke;
  border-radius: 10px;
  display: block;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 10px 20px 0px, rgba(0, 0, 0, 0.06) 0px 0px 0px 1px;
`;
const MarketingLoyaltyCardActions = styled(CardActions) `
  position: absolute;
  bottom: 0;
  width: 400px;
  border-top: 1px solid gray;
`;
export { MarketingLoyaltyListItem, MarketingLoyaltyCardActions };
