import { TableCell, TableRow } from "@mui/material";
import { common, grey } from "@mui/material/colors";
import React from "react";
import { MarketingLoyaltyDiscountsTableBodyPercentCell } from "../MarketingLoyaltyDiscountsTableBodyPercentCell/MarketingLoyaltyDiscountsTableBodyPercentCell";
const MarketingLoyaltyDiscountsTableBodyPercentRow = ({ discount, isEven, }) => {
    const { pointDiscounts, acceptPercentFrom, acceptPercentTo } = discount;
    const background = isEven ? grey[200] : common.white;
    return (React.createElement(TableRow, { sx: { background } },
        React.createElement(TableCell, { align: 'center' },
            acceptPercentFrom,
            "-",
            acceptPercentTo,
            "%"),
        pointDiscounts.map((pointDiscount) => {
            const { id } = pointDiscount;
            return (React.createElement(MarketingLoyaltyDiscountsTableBodyPercentCell, { key: id, pointDiscount: pointDiscount, discountId: discount.id }));
        })));
};
export { MarketingLoyaltyDiscountsTableBodyPercentRow };
