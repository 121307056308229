import "./DateRange.scss";
import React from "react";
import { DatePicker } from "@mui/x-date-pickers";
import { Button } from "@mui/material";
import { TextField } from "@mui/material";
const DateRange = ({ dateFrom, dateTo, dateErrFrom, dateErrTo, setDateFrom, setDateTo, setDateErrFrom, setDateErrTo, dateRangeHandler, subControls, showFindButton = true, title = "Дата выборки:", required = true, isButtonDisabled = false, buttonValue, maxDate, minDate, }) => {
    const setTodayDate = () => {
        const currentDate = new Date();
        const currentDate_formatted = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
        setDateFrom(currentDate_formatted);
        setDateTo(currentDate_formatted);
    };
    const setMonthDate = () => {
        const currentDate = new Date();
        const currentDate_formatted_from = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const currentDate_formatted = new Date(currentDate.getFullYear(), currentDate.getMonth(), Math.max(currentDate.getDate() - 1, 1));
        setDateFrom(currentDate_formatted_from);
        setDateTo(currentDate_formatted);
    };
    return (React.createElement("div", { className: 'dateRange' },
        React.createElement("p", { className: 'title' },
            title,
            React.createElement(Button, { onClick: setTodayDate }, "\u0421\u0435\u0433\u043E\u0434\u043D\u044F"),
            React.createElement(Button, { onClick: setMonthDate }, "\u0422\u0435\u043A\u0443\u0449\u0438\u0439 \u043C\u0435\u0441\u044F\u0446")),
        React.createElement("div", { className: 'dateRange__content' },
            React.createElement(DatePicker, { className: 'dateRange__item', inputFormat: 'yyyy-MM-dd', mask: '____-__-__', label: '\u041E\u0442', value: dateFrom, onChange: (date) => (date || !required) && setDateFrom(date), onError: (err) => setDateErrFrom(!!err), maxDate: maxDate || undefined, minDate: minDate || undefined, renderInput: (props) => (React.createElement(TextField, Object.assign({ helperText: dateErrFrom ? "Некорректная дата" : "", onKeyDown: (e) => e.key === "Enter" && dateRangeHandler(true) }, props))) }),
            React.createElement("span", { className: 'dateRange__devider' }, "\u2013"),
            React.createElement(DatePicker, { className: 'dateRange__item', inputFormat: 'yyyy-MM-dd', mask: '____-__-__', label: '\u0414\u043E', value: dateTo, onChange: (date) => (date || !required) && setDateTo(date), onError: (err) => setDateErrTo(!!err), maxDate: maxDate || undefined, renderInput: (props) => (React.createElement(TextField, Object.assign({ helperText: dateErrTo ? "Некорректная дата" : "", onKeyDown: (e) => e.key === "Enter" && dateRangeHandler(true) }, props))) }),
            React.createElement("div", { className: 'dateRange__content__controls' },
                showFindButton && (React.createElement(Button, { className: 'typografy_buttonSmall', disabled: isButtonDisabled, variant: 'contained', color: 'primary', onClick: () => dateRangeHandler(true) }, buttonValue || "Найти")),
                subControls && subControls))));
};
export { DateRange };
