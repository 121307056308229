var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import axios from "axios";
import { showEventSnack } from "Redux/slices/appSlice";
import store from "Redux/store";
const sendHttpReq = ({ method, url, data, options, authHeader, }) => __awaiter(void 0, void 0, void 0, function* () {
    return yield axios(Object.assign(Object.assign({ method,
        url,
        data }, options), { headers: Object.assign({ Authorization: authHeader ||
                localStorage.getItem("token") ||
                "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJleHAiOjE3MTg2OTM3ODUsInVpZCI6MTY4NDE3fQ.IqzxjkO78lnUgCVUSvGXob8_LkqzzTWPZTOSSo8cHOU" }, options === null || options === void 0 ? void 0 : options.headers), withCredentials: true }))
        .then((res) => res)
        .catch((err) => {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (((_a = err === null || err === void 0 ? void 0 : err.response) === null || _a === void 0 ? void 0 : _a.status) === 401 && ((_b = err === null || err === void 0 ? void 0 : err.response) === null || _b === void 0 ? void 0 : _b.name) === "Unauthorized") {
            localStorage.removeItem("token");
            location.href = "/login";
        }
        else if ((_d = (_c = err === null || err === void 0 ? void 0 : err.response) === null || _c === void 0 ? void 0 : _c.data) === null || _d === void 0 ? void 0 : _d.message) {
            store.dispatch(showEventSnack({ isShowed: true, messageValue: err.response.data.message, type: "err" }));
        }
        else if ((_e = err === null || err === void 0 ? void 0 : err.response) === null || _e === void 0 ? void 0 : _e.message) {
            store.dispatch(showEventSnack({ isShowed: true, messageValue: err.response.message, type: "err" }));
        }
        else if ((_f = err === null || err === void 0 ? void 0 : err.data) === null || _f === void 0 ? void 0 : _f.message) {
            store.dispatch(showEventSnack({ isShowed: true, messageValue: err.data.message, type: "err" }));
        }
        else if ((err === null || err === void 0 ? void 0 : err.status) === 500) {
            store.dispatch(showEventSnack({ isShowed: true, messageValue: err, type: "err" }));
        }
        else if ((err === null || err === void 0 ? void 0 : err.name) === "Error") {
            store.dispatch(showEventSnack({ isShowed: true, messageValue: err.message, type: "err" }));
        }
        else if (((_g = err === null || err === void 0 ? void 0 : err.response) === null || _g === void 0 ? void 0 : _g.data) && ((_h = err.response.data[0]) === null || _h === void 0 ? void 0 : _h.message)) {
            store.dispatch(showEventSnack({ isShowed: true, messageValue: err.response.data[0].message, type: "err" }));
        }
        else if ((err === null || err === void 0 ? void 0 : err.status) === 422) {
            store.dispatch(showEventSnack({ isShowed: true, messageValue: "Поля с ошибками валидации", type: "err" }));
        }
        else if ((err === null || err === void 0 ? void 0 : err.status) === 404) {
            store.dispatch(showEventSnack({ isShowed: true, messageValue: "Не найдено", type: "err" }));
        }
        throw (err === null || err === void 0 ? void 0 : err.response) || (err.message ? JSON.parse(JSON.stringify(err)) : err);
    });
});
export { sendHttpReq };
