import { TableBody } from "@mui/material";
import React, { Fragment } from "react";
import { MarketingLoyaltyDiscountsTableBodyPercentRow, MarketingLoyaltyDiscountsTableBodyPointsRow, } from "../../components";
const MarketingLoyaltyDiscountsTableBody = ({ discounts }) => {
    return (React.createElement(TableBody, null, discounts.map((discount, index) => {
        const { id } = discount;
        const isEven = index % 2 === 0;
        return (React.createElement(Fragment, { key: id },
            React.createElement(MarketingLoyaltyDiscountsTableBodyPercentRow, { discount: discount, isEven: isEven }),
            React.createElement(MarketingLoyaltyDiscountsTableBodyPointsRow, { discount: discount, isEven: isEven })));
    })));
};
export { MarketingLoyaltyDiscountsTableBody };
