export var MarketingTypeToName;
(function (MarketingTypeToName) {
    MarketingTypeToName["register"] = "\u0420\u0435\u0433\u0438\u0441\u0442\u0440\u0430\u0446\u0438\u044F";
    MarketingTypeToName["fillLk"] = "\u0417\u0430\u043F\u043E\u043B\u043D\u0435\u043D\u0438\u0435 \u043B\u0438\u0447\u043D\u043E\u0433\u043E \u043A\u0430\u0431\u0438\u043D\u0435\u0442\u0430";
    MarketingTypeToName["mailingSubscribe"] = "\u041F\u043E\u0434\u043F\u0438\u0441\u043A\u0430 \u043D\u0430 \u0440\u0430\u0441\u0441\u044B\u043B\u043A\u0438";
    MarketingTypeToName["brandPurchase"] = "\u0412\u044B\u043A\u0443\u043F \u0431\u0440\u0435\u043D\u0434\u0430";
    MarketingTypeToName["acceptInRow"] = "\u0412\u044B\u043A\u0443\u043F \u043D\u0435\u0441\u043A\u043E\u043B\u044C\u043A\u0438\u0445 \u0437\u0430\u043A\u0430\u0437\u043E\u0432 \u043F\u043E\u0434\u0440\u044F\u0434";
    MarketingTypeToName["accept"] = "\u0412\u044B\u043A\u0443\u043F \u0432\u0435\u0449\u0435\u0439";
    MarketingTypeToName["pvzDelivery"] = "\u0414\u043E\u0441\u0442\u0430\u0432\u043A\u0430 \u0432 \u041F\u0412\u0417";
    MarketingTypeToName["weekendPurchase"] = "\u041F\u043E\u043A\u0443\u043F\u043A\u0438 \u0432 \u0432\u044B\u0445\u043E\u0434\u043D\u044B\u0435";
    MarketingTypeToName["nightPurchase"] = "\u041F\u043E\u043A\u0443\u043F\u043A\u0438 \u0432 \u043D\u043E\u0447\u043D\u043E\u0435 \u0432\u0440\u0435\u043C\u044F";
    MarketingTypeToName["firstPurchases"] = "\u041F\u0435\u0440\u0432\u044B\u0435 N \u043F\u043E\u043A\u0443\u043F\u043A\u0438";
})(MarketingTypeToName || (MarketingTypeToName = {}));
export const isBrandsSettings = (settings) => {
    return (settings === null || settings === void 0 ? void 0 : settings.brandId) !== undefined;
};
export const isPercentSettings = (settings) => {
    return (settings === null || settings === void 0 ? void 0 : settings.needAcceptPercent) !== undefined;
};
export const isDatesSettings = (settings) => {
    return (settings === null || settings === void 0 ? void 0 : settings.timeFrom) !== undefined;
};
export const isPurchasesSettings = (settings) => {
    return (settings === null || settings === void 0 ? void 0 : settings.purchasesCount) !== undefined;
};
export const isStepPurchasesProductsCountSettings = (settings) => {
    return (settings === null || settings === void 0 ? void 0 : settings.purchasesProductsCount) !== undefined;
};
export const isStepPurchasesCount = (settings) => {
    return (settings === null || settings === void 0 ? void 0 : settings.purchasesCount) !== undefined;
};
