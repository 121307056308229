import { Button, CircularProgress, Pagination, Tab } from "@mui/material";
import { BasicTable } from "Components/BasicTable/BasicTable";
import { usePagination } from "Src/hooks";
import { sendHttpReq } from "Utils/sendHttpReq";
import React, { useEffect, useState } from "react";
import { tableHeadCells } from "./MarketingLoyaltyAdditionalDiscounts.data";
import { MarketingLoyaltyAdditionalDiscountsTableRow, MarketingLoyaltyAdditionalDiscountsDialogForm } from "..";
import { TabContext, TabList } from "@mui/lab";
const MarketingLoyaltyAdditionalDiscounts = () => {
    const { currentPage, onCurrentPageChange } = usePagination();
    const [selectedOrderType, setSelectedOrderType] = useState("im");
    const [additionalDiscounts, setAdditionalDiscounts] = useState({
        currentPage: 1,
        data: [],
        from: 0,
        to: 0,
        pagesCount: 1,
        total: 1,
    });
    const { pagesCount, data: items } = additionalDiscounts;
    const [isAdditionalDiscountsLoading, setIsAdditionalDiscountsLoading] = useState(false);
    const addAdditionalDiscount = (marketingAdditionalDiscount) => {
        setAdditionalDiscounts((prev) => {
            return Object.assign(Object.assign({}, prev), { data: [marketingAdditionalDiscount, ...prev.data] });
        });
    };
    const onEditAdditionalDiscount = (marketingAdditionalDiscount) => {
        setAdditionalDiscounts((prev) => {
            const newData = prev.data.map((item) => {
                if (item.id === marketingAdditionalDiscount.id) {
                    return marketingAdditionalDiscount;
                }
                return item;
            });
            return Object.assign(Object.assign({}, prev), { data: newData });
        });
    };
    const onDeleteAdditionalDiscount = (id) => {
        setAdditionalDiscounts((prev) => {
            const newData = prev.data.filter((item) => item.id !== id);
            return Object.assign(Object.assign({}, prev), { data: newData });
        });
    };
    useEffect(() => {
        setIsAdditionalDiscountsLoading(true);
        const searchParameters = {
            page: currentPage.toString(),
            orderTypes: selectedOrderType,
        };
        sendHttpReq({
            method: "get",
            url: "/loyalty-additional-discount/list?" + new URLSearchParams(searchParameters).toString(),
        })
            .then(({ data }) => setAdditionalDiscounts(data))
            .finally(() => setIsAdditionalDiscountsLoading(false));
    }, [currentPage, selectedOrderType]);
    const [isAddDialogOpen, setIsAddDialogOpen] = useState(false);
    const onAddDialogOpen = () => setIsAddDialogOpen(true);
    const onAddDialogClose = () => setIsAddDialogOpen(false);
    if (isAdditionalDiscountsLoading) {
        return React.createElement(CircularProgress, { className: 'defaultLoader' });
    }
    return (React.createElement("div", null,
        React.createElement(TabContext, { value: selectedOrderType },
            React.createElement(TabList, { onChange: (event, value) => setSelectedOrderType(value), style: { marginBottom: "15px" } },
                React.createElement(Tab, { label: '\u0418\u041C', value: 'im' }),
                React.createElement(Tab, { label: '\u0411\u0443\u0442\u0438\u043A', value: 'boutique' }))),
        React.createElement(Button, { sx: { mb: "1rem" }, variant: 'contained', onClick: onAddDialogOpen }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C"),
        pagesCount > 1 && (React.createElement(Pagination, { sx: { mt: "1rem", mb: "1rem" }, showFirstButton: true, showLastButton: true, page: currentPage, count: pagesCount, onChange: onCurrentPageChange })),
        React.createElement(MarketingLoyaltyAdditionalDiscountsDialogForm, { isAddDialogOpen: isAddDialogOpen, onAddDialogClose: onAddDialogClose, onSuccess: addAdditionalDiscount, selectedOrderType: selectedOrderType }),
        React.createElement(BasicTable, { tableHeadCells: tableHeadCells, items: items, renderRow: (item) => (React.createElement(MarketingLoyaltyAdditionalDiscountsTableRow, { item: item, key: item.id, onEditAdditionalDiscount: onEditAdditionalDiscount, onDeleteAdditionalDiscount: onDeleteAdditionalDiscount })) }),
        pagesCount > 1 && (React.createElement(Pagination, { sx: { mt: "1rem" }, showFirstButton: true, showLastButton: true, page: currentPage, count: pagesCount, onChange: onCurrentPageChange }))));
};
export { MarketingLoyaltyAdditionalDiscounts };
