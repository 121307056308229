import { Table } from "@mui/material";
import styled from "styled-components";
const MarketingLoyaltyDiscountsTableStyle = styled(Table) `
  tr {
    height: 93px;
  }

  tr td {
    height: auto !important;
  }
`;
export { MarketingLoyaltyDiscountsTableStyle };
