import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    token: "",
    user: null,
};
export const userInfoSlice = createSlice({
    name: "userInfo",
    initialState,
    reducers: {
        logout(state) {
            state.user = null;
            state.token = "";
            localStorage.removeItem("token");
        },
        saveAuthToken(state, action) {
            !action.payload ? localStorage.removeItem("token") : localStorage.setItem("token", action.payload);
            state.token = action.payload;
        },
        saveUserData(state, action) {
            state.user = action.payload;
        },
    },
});
export const { logout, saveAuthToken, saveUserData } = userInfoSlice.actions;
export default userInfoSlice.reducer;
