import { LoadingButton } from "@mui/lab";
import { Button, CardContent, Typography } from "@mui/material";
import React, { useState } from "react";
import { MarketingLoyaltyCardActions, MarketingLoyaltyListItem } from "./MarketingLoyaltyAchievementsCard.styles";
import { MarketingLoyaltyCardSettings, MarketingLoyaltyCardSteps, MarketingLoyaltyForm } from "../../components";
import { MarketingTypeToName } from "Src/types";
const MarketingLoyaltyCard = ({ achievement, onUpdateAchievement, onDeleteAchievement, brands, }) => {
    const { name, description, id, activityPeriod, enabled, pointsCount, settings, steps, type } = achievement;
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const onEditAchievement = () => setOpen(true);
    return (React.createElement(MarketingLoyaltyListItem, null,
        React.createElement(CardContent, null,
            React.createElement(Typography, { sx: { fontSize: 14 }, color: 'primary', gutterBottom: true }, MarketingTypeToName[type]),
            React.createElement(Typography, { variant: 'h5', component: 'div' }, name),
            React.createElement(Typography, { sx: { mb: 1.5 }, color: 'text.secondary' }, description),
            React.createElement(Typography, { variant: 'subtitle1' }, enabled ? "Включено" : "Не включено"),
            React.createElement(Typography, { variant: 'subtitle1' },
                "\u0421\u0440\u043E\u043A \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u0431\u0430\u043B\u043B\u043E\u0432:",
                " ",
                React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, activityPeriod !== null && activityPeriod !== void 0 ? activityPeriod : "Бессрочный")),
            pointsCount && (React.createElement(Typography, { variant: 'subtitle1' },
                "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0431\u0430\u043B\u043B\u043E\u0432:",
                " ",
                React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, pointsCount))),
            React.createElement(MarketingLoyaltyCardSettings, { settings: settings, brands: brands }),
            React.createElement(MarketingLoyaltyCardSteps, { steps: steps })),
        React.createElement(MarketingLoyaltyCardActions, null,
            React.createElement(Button, { color: 'primary', size: 'small', onClick: onEditAchievement }, "\u0420\u0435\u0434\u0430\u043A\u0442\u0438\u0440\u043E\u0432\u0430\u0442\u044C"),
            React.createElement(LoadingButton, { color: 'error', size: 'small', onClick: () => onDeleteAchievement(id) }, "\u0423\u0434\u0430\u043B\u0438\u0442\u044C")),
        React.createElement(MarketingLoyaltyForm, { onUpdateAchievement: onUpdateAchievement, achievement: achievement, open: open, brands: brands, handleClose: handleClose })));
};
export { MarketingLoyaltyCard };
