import React, { useState } from "react";
import { Collapse, IconButton, TableCell, TableRow } from "@mui/material";
import { getUserAdminUrl } from "Utils/urls";
import { dateStringToLocal, formatPhoneNumber } from "Src/utils";
import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import { CertificatePayments } from "Pages/Certificates/components/CertificatePayments";
export const CertificateListItem = ({ certificate }) => {
    var _a;
    const [isHistoryShown, setIsHistoryShown] = useState(false);
    return React.createElement(React.Fragment, null,
        React.createElement(TableRow, null,
            React.createElement(TableCell, null,
                React.createElement(IconButton, { size: 'small', onClick: () => setIsHistoryShown((prev) => !prev) }, isHistoryShown ? React.createElement(KeyboardArrowUp, null) : React.createElement(KeyboardArrowDown, null))),
            React.createElement(TableCell, null, certificate.id),
            React.createElement(TableCell, null, ((_a = certificate.card) === null || _a === void 0 ? void 0 : _a.number) || ""),
            React.createElement(TableCell, null,
                React.createElement("div", null,
                    "\u0417\u0430\u043A\u0430\u0437\u0447\u0438\u043A:",
                    React.createElement("a", { href: getUserAdminUrl(certificate.customer.id) },
                        " ",
                        certificate.customer.name || "(без имени)",
                        " "),
                    "\u0422\u0435\u043B: ",
                    formatPhoneNumber(certificate.customer.phone)),
                certificate.client ? React.createElement("div", null,
                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044C:",
                    React.createElement("a", { href: getUserAdminUrl(certificate.client.id) },
                        " ",
                        certificate.client.name || "(без имени)",
                        " "),
                    "\u0422\u0435\u043B: ",
                    formatPhoneNumber(certificate.client.phone)) : React.createElement("div", null,
                    "\u041F\u043E\u043B\u0443\u0447\u0430\u0442\u0435\u043B\u044C: \u0442\u0435\u043B. ",
                    certificate.phone,
                    ", E-mail: ",
                    certificate.email)),
            React.createElement(TableCell, null, certificate.status.name),
            React.createElement(TableCell, null,
                React.createElement("div", null,
                    "\u041E\u0431\u0449\u0430\u044F \u0441\u0443\u043C\u043C\u0430: ",
                    certificate.amount),
                React.createElement("div", null,
                    "\u041E\u0441\u0442\u0430\u0442\u043E\u043A: ",
                    certificate.remainAmount)),
            React.createElement(TableCell, null,
                dateStringToLocal(certificate.activationDateTime),
                " - ",
                dateStringToLocal(certificate.activeUntil))),
        React.createElement(TableRow, null,
            React.createElement(TableCell, { className: 'simpleTable__body__row__collapsedInfo_brown', style: { padding: "0" }, colSpan: 100 },
                React.createElement(Collapse, { in: isHistoryShown, timeout: 'auto', unmountOnExit: true },
                    React.createElement("div", { style: { padding: "10px" } },
                        React.createElement("p", { className: 'typografy_body1' }, "\u0418\u0441\u0442\u043E\u0440\u0438\u044F \u0442\u0440\u0430\u043D\u0437\u0430\u043A\u0446\u0438\u0439:"),
                        certificate.payments.length === 0 && React.createElement("div", null, "\u041F\u0443\u0441\u0442\u043E"),
                        certificate.payments.length > 0 && (React.createElement(CertificatePayments, { certificate: certificate })))))));
};
