import React, { Suspense, useEffect } from "react";
import { LinearProgress, Typography } from "@mui/material";
import { NavBar } from "Components/NavBar/NavBar";
import { Marketing, Orders } from "Pages/index";
import Certificates from "Pages/Certificates/Certificates";
import { Route, Routes } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { saveUserData } from "Redux/slices/userInfoSlice";
import { siteUrl } from "Src/variables";
import { sendHttpReq } from "Utils/index";
import { OrdersCourierDeliveries } from "Pages/Orders/components";
const Router = () => {
    const dispatch = useAppDispatch();
    const { user } = useAppSelector((state) => state.userInfo);
    useEffect(() => {
        sendHttpReq({ method: "get", url: "/user" })
            .then(({ data }) => dispatch(saveUserData(data)))
            .catch(() => {
            location.href = siteUrl;
        });
    }, []);
    if (!(user === null || user === void 0 ? void 0 : user.id))
        return null;
    return (React.createElement(Suspense, { fallback: React.createElement(LinearProgress, { value: 10 }) },
        React.createElement(NavBar, { user: user }),
        React.createElement(Routes, null,
            React.createElement(Route, { path: '/', element: React.createElement("p", { style: { textAlign: "center" } }, "\u0414\u043E\u043C\u0430\u0448\u043D\u044F\u044F \u0441\u0442\u0440\u0430\u043D\u0438\u0446\u0430") }),
            React.createElement(Route, { path: 'orders', element: React.createElement(Orders, null) },
                React.createElement(Route, { index: true, element: React.createElement(Typography, { align: 'center', variant: 'h5' }, "\u0421\u0442\u0440\u0430\u043D\u0438\u0446\u0430 \u043F\u043E\u043A\u0430 \u043D\u0435 \u0433\u043E\u0442\u043E\u0432\u0430") }),
                React.createElement(Route, { path: 'deliveries', element: React.createElement(OrdersCourierDeliveries, null) })),
            React.createElement(Route, { path: '/marketing', element: React.createElement(Marketing, null) }),
            React.createElement(Route, { path: '/certificates', element: React.createElement(Certificates, null) }),
            React.createElement(Route, { path: '*', element: React.createElement("p", { style: { textAlign: "center", padding: "50px" } }, "404 Page not found") }))));
};
export { Router };
