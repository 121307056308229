import { oldAdminUrl } from "Src/variables";
const oldAdminUrlOrders = `${oldAdminUrl}?section=Orders`;
const oldAdminOrdersLinks = [
    { href: oldAdminUrlOrders, name: "Новые" },
    { href: `${oldAdminUrlOrders}&view=process`, name: "Обработка" },
    { href: `${oldAdminUrlOrders}&view=delivery`, name: "Доставка" },
    { href: `${oldAdminUrlOrders}&view=done`, name: "Выполнены" },
    { href: `${oldAdminUrlOrders}&view=pickup`, name: "Самовывоз" },
    { href: `${oldAdminUrl}?section=Special_orders`, name: "Спец. заказы" },
    { href: `${oldAdminUrl}?section=Shipments`, name: "Реестр отправки" },
    { href: `${oldAdminUrl}?section=Orders&view=filter`, name: "Фильтр" },
    { href: `${oldAdminUrl}?section=Orders&view=cancel`, name: "Отмененные" },
    { href: `${oldAdminUrl}?section=Orders&view=search`, name: "Поиск" },
];
export { oldAdminOrdersLinks };
