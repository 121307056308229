import { TabContext, TabPanel } from "@mui/lab";
import { Tab } from "@mui/material";
import React from "react";
import { useTabs } from "Src/hooks";
import { MarketingLoyaltyTabList } from "./MarketingLoyalty.styles";
import { MarketingLoyaltyDiscounts, MarketingLoyaltyAchievements } from "../../components";
import { MarketingLoyaltyDiscountsContextProvider } from "Src/context";
import { MarketingLoyaltyAdditionalDiscounts } from "../MarketingLoyaltyAdditionalDiscounts";
const MarketingLoyalty = () => {
    const [activeTab, tabHandler] = useTabs("achievements", true);
    return (React.createElement("div", null,
        React.createElement(TabContext, { value: activeTab },
            React.createElement(MarketingLoyaltyTabList, { centered: true, indicatorColor: 'primary', variant: 'fullWidth', onChange: tabHandler },
                React.createElement(Tab, { label: '\u0414\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u044F', value: 'achievements' }),
                React.createElement(Tab, { label: '\u0422\u0430\u0431\u043B\u0438\u0446\u0430 \u0441\u043A\u0438\u0434\u043E\u043A', value: 'discounts' }),
                React.createElement(Tab, { label: '\u0414\u043E\u043F\u043E\u043B\u043D\u0438\u0442\u0435\u043B\u044C\u043D\u044B\u0435 \u0441\u043A\u0438\u0434\u043A\u0438', value: 'additional-discounts' })),
            React.createElement(TabPanel, { value: 'achievements' },
                React.createElement(MarketingLoyaltyAchievements, null)),
            React.createElement(TabPanel, { value: 'discounts' },
                React.createElement(MarketingLoyaltyDiscountsContextProvider, null,
                    React.createElement(MarketingLoyaltyDiscounts, null))),
            React.createElement(TabPanel, { value: 'additional-discounts', style: { paddingTop: "0" } },
                React.createElement(MarketingLoyaltyAdditionalDiscounts, null)))));
};
export { MarketingLoyalty };
