import { showEventSnack } from "Redux/slices/appSlice";
import store from "Redux/store";
export const isInvalidDate = (dateFrom, dateTo, dateErrFrom, dateErrTo) => {
    if (!dateFrom || !dateTo || dateErrFrom || dateErrTo) {
        store.dispatch(showEventSnack({ isShowed: true, messageValue: "Укажите корректную дату", type: "err" }));
        return true;
    }
    else if (dateFrom > dateTo) {
        store.dispatch(showEventSnack({ isShowed: true, messageValue: "Укажите корректный диапазон", type: "err" }));
        return true;
    }
    return false;
};
