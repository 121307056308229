import React from "react";
import { Tabs } from "@mui/material";
import { useTitle } from "Src/hooks";
import { Outlet, useLocation } from "react-router-dom";
import { LinkTab } from "Components/LinkTab";
import { orderTabs } from "./data";
import { OrdersOldAdminMenu } from "./components/OrdersOldAdminMenu";
const Orders = () => {
    useTitle("Заказы");
    const { pathname } = useLocation();
    const currentTabIndex = orderTabs.findIndex((orderTab) => orderTab.to === pathname);
    return (React.createElement("div", { className: 'defaultPageWrapper' },
        React.createElement(OrdersOldAdminMenu, null),
        React.createElement(Tabs, { sx: { mt: "10px", mb: "30px" }, variant: 'fullWidth', centered: true, value: currentTabIndex, role: 'navigation' }, orderTabs.map((orderTab) => (React.createElement(LinkTab, Object.assign({ key: orderTab.to }, orderTab))))),
        React.createElement(Outlet, null)));
};
export { Orders };
