import { useState } from "react";
const currentDate = new Date();
const currentDate_formatted = new Date(currentDate.getFullYear(), currentDate.getMonth(), currentDate.getDate());
const useDatePicker = () => {
    const [dateFrom, setDateFrom] = useState(new Date(currentDate_formatted.getTime() - 86400000 * 7));
    const [dateTo, setDateTo] = useState(currentDate_formatted);
    const [dateErrFrom, setDateErrFrom] = useState(false);
    const [dateErrTo, setDateErrTo] = useState(false);
    return { dateFrom, setDateFrom, dateTo, setDateTo, dateErrFrom, setDateErrFrom, dateErrTo, setDateErrTo };
};
export { useDatePicker };
