import { ExpandCircleDown } from "@mui/icons-material";
import { CardContent, Collapse, IconButton, List, ListItem, Typography } from "@mui/material";
import React, { useState } from "react";
import { isStepPurchasesCount, isStepPurchasesProductsCountSettings, } from "Src/types/marketing/marketingAchievements";
import { StepHeader } from "./MarketingLoyaltyAchievementsCardSteps.styes";
const MarketingLoyaltyCardSteps = ({ steps }) => {
    const [showSteps, setShowSteps] = useState(false);
    const toggleShowSteps = () => setShowSteps((prev) => !prev);
    if (!steps.length)
        return null;
    return (React.createElement(React.Fragment, null,
        React.createElement(StepHeader, null,
            React.createElement(Typography, { variant: 'h6' }, "\u042D\u0442\u0430\u043F\u044B"),
            React.createElement(IconButton, { onClick: toggleShowSteps },
                React.createElement(ExpandCircleDown, { style: { transform: showSteps ? "rotate(180deg)" : "initial" } }))),
        React.createElement(Collapse, { in: showSteps, timeout: 'auto', unmountOnExit: true },
            React.createElement(CardContent, null,
                React.createElement(List, null, steps.map((step) => {
                    const { id, description, name, pointsCount, settings } = step;
                    return (React.createElement(ListItem, { key: id, sx: { listStyle: "decimal", display: "list-item" } },
                        React.createElement(Typography, { variant: 'h6', component: 'div' }, name),
                        React.createElement(Typography, { sx: { mb: 1.5 }, color: 'text.secondary' }, description),
                        pointsCount && (React.createElement(Typography, { variant: 'subtitle1' },
                            "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0431\u0430\u043B\u043B\u043E\u0432:",
                            " ",
                            React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, pointsCount))),
                        isStepPurchasesProductsCountSettings(settings) && (React.createElement(Typography, { variant: 'subtitle1' },
                            "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u0432\u044B\u043A\u0443\u043F\u043B\u0435\u043D\u043D\u044B\u0445 \u0432\u0435\u0449\u0435\u0439:",
                            " ",
                            React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, settings.purchasesProductsCount))),
                        isStepPurchasesCount(settings) && (React.createElement(Typography, { variant: 'subtitle1' },
                            "\u041A\u043E\u043B\u0438\u0447\u0435\u0441\u0442\u0432\u043E \u043F\u043E\u043A\u0443\u043F\u043E\u043A:",
                            " ",
                            React.createElement(Typography, { variant: 'subtitle2', component: 'span', color: 'text.secondary' }, settings.purchasesCount)))));
                }))))));
};
export { MarketingLoyaltyCardSteps };
