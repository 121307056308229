import "./App.scss";
import React from "react";
import { useAppDispatch, useAppSelector } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { IconButton, Snackbar } from "@mui/material";
import { Close, ErrorOutline } from "@mui/icons-material";
import { Router } from "..";
const App = () => {
    const { app: { snackMsg }, } = useAppSelector((state) => state);
    const dispatch = useAppDispatch();
    const closeErr = () => dispatch(showEventSnack({ isShowed: false, messageValue: "", type: "info" }));
    return (React.createElement("main", null,
        React.createElement(Router, null),
        React.createElement(Snackbar, { className: "snackbarEvent" + " snackbarEvent_" + snackMsg.type, anchorOrigin: {
                horizontal: "left",
                vertical: "top",
            }, open: snackMsg.isShowed, autoHideDuration: 7000, onClose: closeErr, message: React.createElement(React.Fragment, null,
                React.createElement(ErrorOutline, null),
                " ",
                React.createElement("p", null, snackMsg.text)), action: React.createElement(IconButton, { size: 'small', color: 'inherit', onClick: closeErr },
                React.createElement(Close, { fontSize: 'small' })) })));
};
export { App };
