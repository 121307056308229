import { CircularProgress } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { MarketingLoyaltyDiscountsContext } from "Src/context/MarketingLoyaltyDiscountsContextProvider";
import { sendHttpReq } from "Utils/sendHttpReq";
import { MarketingLoyaltyDiscountsHeader, MarketingLoyaltyDiscountsTable } from "../../components";
const MarketingLoyaltyDiscounts = () => {
    const dispatch = useAppDispatch();
    const { discounts, setDiscounts } = useContext(MarketingLoyaltyDiscountsContext);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        setIsLoading(true);
        sendHttpReq({ method: "get", url: "/discount-point-settings" })
            .then(({ data }) => setDiscounts(data))
            .catch(() => dispatch(showEventSnack({ type: "err", isShowed: true, messageValue: "Не удалось загрузить таблицу скидок" })))
            .finally(() => setIsLoading(false));
    }, []);
    if (isLoading) {
        return React.createElement(CircularProgress, { value: 10, className: 'defaultLoader' });
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(MarketingLoyaltyDiscountsHeader, { discounts: discounts, setDiscounts: setDiscounts }),
        React.createElement(MarketingLoyaltyDiscountsTable, { discounts: discounts })));
};
export { MarketingLoyaltyDiscounts };
