export const apiUrl = process.env.API_URL
    ? process.env.API_URL
    : process.env.NODE_ENV === "development"
        ? "https://api2.dev.lsboutique.ru/apix/admin/"
        : "https://api2.lsboutique.ru/apix/admin/";
export const crmApiUrl = apiUrl.replace("apix/admin/", "apix/crm");
export const isDev = process.env.NODE_ENV === "development";
export const oldAdminHost = process.env.ADMIN_HOST
    ? process.env.ADMIN_HOST
    : `https://${isDev ? "dev." : ""}lsboutique.ru`;
export const oldAdminUrl = oldAdminHost + "/admin/index.php";
export const siteUrl = process.env.SITE_URL
    ? process.env.SITE_URL
    : isDev
        ? "https://dev.lsboutique.ru"
        : "https://lsboutique.ru";
