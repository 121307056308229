import { createSlice } from "@reduxjs/toolkit";
const initialState = {
    snackMsg: { text: "", isShowed: false, type: "" },
};
export const appSlice = createSlice({
    name: "app",
    initialState,
    reducers: {
        showEventSnack(state, action) {
            const { isShowed, messageValue, type } = action.payload;
            let text;
            if (typeof messageValue === "string") {
                text = messageValue;
            }
            else if (messageValue.data) {
                if (Array.isArray(messageValue.data)) {
                    text = messageValue.data[0].message;
                }
                else {
                    text = messageValue.data.message;
                }
            }
            else if (messageValue.message) {
                text = messageValue.message;
            }
            else {
                text = "Ошибка сервера";
            }
            state.snackMsg = { text, isShowed, type };
        },
    },
});
export const { showEventSnack } = appSlice.actions;
export default appSlice.reducer;
