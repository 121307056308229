const tableHeadCells = [
    { name: "От" },
    { name: "До" },
    { name: "Процент повышения скидки" },
    { name: "Не включать скидку клиентам с оформленным заказом" },
    { name: "Включено" },
    { name: "Список id клиентов" },
    { name: "Статус клиентов" },
    { name: "Действия" },
];
export { tableHeadCells };
