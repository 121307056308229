import React from "react";
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { oldAdminHost } from "Src/variables";
import { Receipt } from "@mui/icons-material";
export const CertificatePayments = ({ certificate }) => {
    return React.createElement(Box, { className: 'simpleTable__body__row__collapsedItem', style: { marginTop: "10px" } },
        React.createElement(TableContainer, { className: 'simpleTable__body__row__collapsedItem__subTable', component: Paper },
            React.createElement(Table, null,
                React.createElement(TableHead, null,
                    React.createElement(TableRow, null,
                        React.createElement(TableCell, null, "\u0422\u0438\u043F"),
                        React.createElement(TableCell, null, "\u0421\u0443\u043C\u043C\u0430"),
                        React.createElement(TableCell, null, "\u0414\u0430\u0442\u0430"),
                        React.createElement(TableCell, null, "\u041C\u0435\u0442\u043E\u0434 \u043E\u043F\u043B\u0430\u0442\u044B"),
                        React.createElement(TableCell, null, "\u0417\u0430\u043A\u0430\u0437"))),
                React.createElement(TableBody, null, certificate.payments.map((payment) => {
                    var _a;
                    return (React.createElement(TableRow, { key: payment.id },
                        React.createElement(TableCell, { style: { color: payment.amount > 0 ? "green" : "red" } }, payment.amount > 0 ? "Зачисление" : "Списание"),
                        React.createElement(TableCell, null, payment.amount),
                        React.createElement(TableCell, null, payment.date),
                        React.createElement(TableCell, null,
                            ((_a = payment.paymentMethod) === null || _a === void 0 ? void 0 : _a.name) || "",
                            payment.receiptData && (React.createElement("a", { href: payment.receiptData.url, target: '_blank', rel: 'noopener noreferrer', style: { marginLeft: "10px" } },
                                React.createElement(Receipt, { style: { verticalAlign: "middle" } }),
                                "\u0427\u0435\u043A"))),
                        React.createElement(TableCell, null, payment.order && (React.createElement("a", { href: `${oldAdminHost}/admin/index.php?section=Order&order_id=${payment.order.id}`, target: '_blank', rel: 'noopener noreferrer' }, payment.order.id)))));
                })))));
};
