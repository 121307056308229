import { Link, Typography } from "@mui/material";
import { oldAdminOrdersLinks } from "Pages/Orders/data/oldAdminOrdersLinks";
import React from "react";
const OrdersOldAdminMenu = () => {
    return (React.createElement("div", null,
        React.createElement(Typography, { align: 'center', variant: 'h6', sx: { mb: "1rem" } }, "\u041C\u0435\u043D\u044E \u0434\u043B\u044F \u043F\u0435\u0440\u0435\u0445\u043E\u0434\u0430 \u043D\u0430 \u0441\u0442\u0430\u0440\u0443\u044E \u0430\u0434\u043C\u0438\u043D\u043A\u0443"),
        React.createElement("ul", { style: { display: "flex", alignItems: "center", justifyContent: "center", gap: "2rem", marginBottom: "3rem" } }, oldAdminOrdersLinks.map((oldAdminOrdersLink) => {
            const { href, name } = oldAdminOrdersLink;
            return (React.createElement(Link, { key: href, href: href, target: '_blank', referrerPolicy: 'no-referrer' }, name));
        }))));
};
export { OrdersOldAdminMenu };
