import { LoadingButton } from "@mui/lab";
import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormControlLabel, InputLabel, ListItemText, MenuItem, Select, TextField, } from "@mui/material";
import React, { useState } from "react";
import { useAppDispatch } from "Redux/hooks";
import { showEventSnack } from "Redux/slices/appSlice";
import { sendHttpReq } from "Utils/sendHttpReq";
import { MarketingLoyaltyFormOptionalInputs } from "../MarketingLoyaltyAchievementsFormOptionalInputs/MarketingLoyaltyAchievementsFormOptionalInputs";
import { MarketingTypeToName } from "Src/types";
const MarketingLoyaltyForm = ({ achievement, brands, handleClose, isNew = false, onUpdateAchievement, open, }) => {
    const dispatch = useAppDispatch();
    const [achievementData, setAchievementData] = useState(achievement);
    const { name, description, id, activityPeriod, enabled, type, pointsCount, settings, steps } = achievementData;
    const onChange = (e) => {
        setAchievementData((prev) => (Object.assign(Object.assign({}, prev), { [e.target.id]: e.target.value })));
    };
    const onTypeChange = (e) => {
        setAchievementData((prev) => (Object.assign(Object.assign({}, prev), { type: e.target.value, steps: [], settings: null })));
    };
    const onEnabledChange = (e) => {
        setAchievementData((prev) => (Object.assign(Object.assign({}, prev), { [e.target.id]: e.target.checked })));
    };
    const [isLoading, setIsLoading] = useState(false);
    const onSubmit = () => {
        setIsLoading(true);
        const method = isNew ? "post" : "put";
        const url = isNew ? "/achievements" : `/achievements/${id}`;
        sendHttpReq({ method, url, data: achievementData })
            .then(({ data }) => {
            onUpdateAchievement(data);
            handleClose();
            dispatch(showEventSnack({
                isShowed: true,
                messageValue: `Достижение успешно ${isNew ? "создано" : "отредактировано"}`,
                type: "success",
            }));
        })
            .catch(() => { })
            .finally(() => setIsLoading(false));
    };
    return (React.createElement(Dialog, { open: open, onClose: handleClose },
        React.createElement(DialogTitle, null,
            isNew ? "Создание" : "Редактирование",
            " \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u044F"),
        React.createElement(DialogContent, null,
            React.createElement(FormControl, { margin: 'dense', variant: 'outlined', fullWidth: true, size: 'small' },
                React.createElement(InputLabel, { id: 'marketing-type' }, "\u0422\u0438\u043F \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u044F"),
                React.createElement(Select, { value: type, onChange: onTypeChange, labelId: 'marketing-type', label: '\u0422\u0438\u043F \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u044F' }, Object.entries(MarketingTypeToName).map(([value, name], index) => (React.createElement(MenuItem, { key: index, value: value },
                    React.createElement(ListItemText, { primary: name })))))),
            React.createElement(TextField, { value: name, onChange: onChange, autoFocus: true, margin: 'dense', id: 'name', label: '\u041D\u0430\u0437\u0432\u0430\u043D\u0438\u0435', fullWidth: true, variant: 'outlined' }),
            React.createElement(TextField, { rows: 2, multiline: true, value: description, onChange: onChange, margin: 'dense', id: 'description', label: '\u041E\u043F\u0438\u0441\u0430\u043D\u0438\u0435', fullWidth: true, variant: 'outlined' }),
            React.createElement(FormControlLabel, { control: React.createElement(Checkbox, { id: 'enabled', color: 'primary', checked: enabled, onChange: onEnabledChange }), label: '\u0412\u043A\u043B\u044E\u0447\u0435\u043D\u043E' }),
            React.createElement(TextField, { type: 'number', value: activityPeriod || 0, onChange: onChange, margin: 'dense', id: 'activityPeriod', label: '\u0421\u043A\u043E\u043B\u044C\u043A\u043E \u0434\u043D\u0435\u0439 \u0434\u0435\u0439\u0441\u0442\u0432\u0443\u0435\u0442 \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u0435', fullWidth: true, variant: 'outlined' }),
            React.createElement(MarketingLoyaltyFormOptionalInputs, { brands: brands, onChange: onChange, pointsCount: pointsCount, setAchievementData: setAchievementData, settings: settings, steps: steps, type: type })),
        React.createElement(DialogActions, null,
            React.createElement(LoadingButton, { loading: isLoading, variant: 'contained', color: 'success', onClick: onSubmit }, "\u0421\u043E\u0445\u0440\u0430\u043D\u0438\u0442\u044C"),
            React.createElement(Button, { variant: 'contained', color: 'error', onClick: handleClose }, "\u0417\u0430\u043A\u0440\u044B\u0442\u044C"))));
};
export { MarketingLoyaltyForm };
