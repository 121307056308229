import { configureStore } from "@reduxjs/toolkit";
import appSlice from "Redux/slices/appSlice";
import userInfoSlice from "./slices/userInfoSlice";
const store = configureStore({
    reducer: {
        app: appSlice,
        userInfo: userInfoSlice,
    },
    devTools: window && window.location && window.location.host !== "lsboutique.ru",
});
export default store;
