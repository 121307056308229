import React, { useEffect, useState } from "react";
import { Button } from "@mui/material";
import { useAppDispatch } from "Redux/hooks";
import { sendHttpReq } from "Utils/sendHttpReq";
import { showEventSnack } from "Redux/slices/appSlice";
import { MarketingLoyaltyForm, MarketingLoyaltyAchievementsList } from "../../components";
import { newAchievement } from "Src/constants";
const MarketingLoyaltyAchievements = () => {
    const dispatch = useAppDispatch();
    const [achievements, setAchievements] = useState([]);
    const [open, setOpen] = useState(false);
    const handleClose = () => setOpen(false);
    const onAddNewAchievement = () => setOpen(true);
    const onUpdateNewAchievement = (updatedAchievement) => {
        setAchievements((prevAchievements) => [...prevAchievements, updatedAchievement]);
    };
    const onUpdateAchievement = (updatedAchievement) => {
        setAchievements((prevAchievements) => prevAchievements.map((prevAchievement) => {
            if (prevAchievement.id === updatedAchievement.id) {
                return updatedAchievement;
            }
            return prevAchievement;
        }));
    };
    const onDeleteAchievement = (id) => {
        if (!confirm(`Удалить достижение?`))
            return;
        sendHttpReq({ method: "delete", url: `/achievements/${id}` })
            .then(() => {
            setAchievements((prevAchievements) => {
                return prevAchievements.filter((prevAchievement) => prevAchievement.id !== id);
            });
            dispatch(showEventSnack({ isShowed: true, type: "success", messageValue: "Достижение успешно удалено" }));
        })
            .catch(() => {
            dispatch(showEventSnack({ isShowed: true, type: "err", messageValue: "Не удалось удалить достижение" }));
        });
    };
    const [brands, setBrands] = useState([]);
    useEffect(() => {
        sendHttpReq({ method: "get", url: "/brands/available" })
            .then(({ data }) => setBrands(data))
            .catch(() => {
            dispatch(showEventSnack({ isShowed: true, type: "err", messageValue: "Не удалось загрузить доступные бренды" }));
        });
    }, []);
    return (React.createElement("div", { className: 'defaultPageWrapper' },
        React.createElement(Button, { color: 'primary', variant: 'contained', onClick: onAddNewAchievement, sx: { mb: 2 } }, "\u0414\u043E\u0431\u0430\u0432\u0438\u0442\u044C \u0434\u043E\u0441\u0442\u0438\u0436\u0435\u043D\u0438\u0435"),
        open && (React.createElement(MarketingLoyaltyForm, { achievement: newAchievement, brands: brands, handleClose: handleClose, isNew: true, onUpdateAchievement: onUpdateNewAchievement, open: open })),
        React.createElement(MarketingLoyaltyAchievementsList, { achievements: achievements, brands: brands, onDeleteAchievement: onDeleteAchievement, onUpdateAchievement: onUpdateAchievement, setAchievements: setAchievements })));
};
export { MarketingLoyaltyAchievements };
