import React from "react";
import { createContext, useState } from "react";
const defaultValue = {
    discounts: [],
    setDiscounts: () => { },
};
export const MarketingLoyaltyDiscountsContext = createContext(defaultValue);
export const MarketingLoyaltyDiscountsContextProvider = ({ children }) => {
    const [discounts, setDiscounts] = useState([]);
    return (React.createElement(MarketingLoyaltyDiscountsContext.Provider, { value: { discounts, setDiscounts } }, children));
};
